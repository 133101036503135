import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { RevisionService } from '../services/revision/revision.service';
import { tap } from 'rxjs/operators';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../services/dialog.service';
import { TranslationService } from '../services/translation.service';
import { ENVIRONMENT } from '../../environments/environment';
import { Logger } from '../logger';

let errorDialog: MatDialogRef<ErrorDialogComponent>;

export function revisionGuard(): CanActivateFn {
  return (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    const dialogService = inject(DialogService);
    const translationService = inject(TranslationService);
    const router = inject(Router);
    const revisionService = inject(RevisionService);
    const logger = new Logger('REVISION_GUARD');

    const openErrorDialog = (): void => {
      if (errorDialog) {
        errorDialog.close();
      }

      errorDialog = dialogService.openDialog(ErrorDialogComponent, {
        hasBackdrop: true,
        data: {
          errorIcon: 'enthus-credit_card_off',
          errorIconIsPortal: true,
          errorInfoMessage: translationService.translations.error.Revision.AppUpdateFailed.toString(),
          errorHeader: translationService.translations.headers.Revision.AppUpdateError.toString(),
          feedbackCategoryId: 3,
          feedbackDialogHeader: translationService.translations.headers.Revision.CheckoutFeedback.toString(),
        },
      });
    };

    let hasCorrectRevision = false;
    const lastCheckedRevision = localStorage.getItem('checkedRevision');
    localStorage.removeItem('checkedRevision');

    const shouldEnforceRevisionGuard = ENVIRONMENT.enforceRevisionGuard || ENVIRONMENT.git.revision;

    if (!shouldEnforceRevisionGuard) {
      return of(true);
    }

    return revisionService.hasCorrectRevision().pipe(
      tap((correctRevision) => (hasCorrectRevision = correctRevision)),
      switchMap(() => {
        logger.infoVerbose('has correct revision', hasCorrectRevision);

        if (hasCorrectRevision) {
          return of(true).pipe(tap(() => logger.infoVerbose('allow navigation')));
        } else {
          if (!lastCheckedRevision || ENVIRONMENT.git.revision !== lastCheckedRevision) {
            localStorage.setItem('checkedRevision', ENVIRONMENT.git.revision);
            const path = ENVIRONMENT.production ? ENVIRONMENT.basePath + state.url : state.url;
            location.href = location.origin + path;
          } else {
            logger.infoVerbose('revision was checked already');
            router.navigate(['cart']);
            openErrorDialog();
          }

          return of(false).pipe(tap(() => logger.infoVerbose('prevent navigation')));
        }
      })
    );
  };
}
