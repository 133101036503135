import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { DropdownValuesService } from '../services/dropdown-values.service';
import { FeedbackService } from '../services/feedback.service';
import { skip, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FeedbackModule } from '../feedback/feedback.module';

@Component({
  selector: 'portal-route-not-found',
  standalone: true,
  templateUrl: './route-not-found.component.html',
  styleUrls: ['./route-not-found.component.scss'],
  imports: [FeedbackModule],
})
export class RouteNotFoundComponent implements OnDestroy {
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public translationService: TranslationService,
    private _dropdownValuesService: DropdownValuesService,
    private _feedbackService: FeedbackService,
    private _router: Router
  ) {
    this._dropdownValuesService.initializeDropdownValues();
    this._dropdownValuesService.dropdownValues$
      .pipe(
        takeUntil(this._destroyEmitter),
        skip(1),
        tap(() => {
          this._feedbackService.feedbackForm.get('FeedbackCategory').setValue(3);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  public navigateToStartRoute(): void {
    this._router.navigate(['/']);
  }
}
