import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

export interface IIfUserIsAuthenticatedGuardParams {
  /**
   * Optional (Defaults to true)
   *
   * Can the route be activated with this guard if the user IS authorized or if he IS NOT authorized?
   *
   * True - Route can be activated only when the user is authorized.<br>
   * False - Route can be activated only when the user is unauthorized.
   */
  allowNavigationIfAuthenticationIs?: boolean;
  /**
   * Optional (Defaults to false)
   *
   * If the async authentication guard runs into an error, should still be navigated to the target?<br>
   * This will override the behavior of {@link allowNavigationIfAuthenticationIs} if an error occurred!
   *
   * (This is used for example on the error route that we want to display even when requests etc. throw errors.)
   */
  allowNavigationOnError?: boolean;
  /**
   * Optional
   *
   * Route to navigate to if navigation was prevented and this property is set.
   */
  route?: string[];
}

export function userAuthenticationGuard({
  allowNavigationIfAuthenticationIs = true,
  allowNavigationOnError = false,
  route,
}: IIfUserIsAuthenticatedGuardParams): CanActivateFn {
  return () => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);

    return authenticationService.isUserAuthenticated().pipe(
      map((isAuthenticated) => {
        if (isAuthenticated !== allowNavigationIfAuthenticationIs) {
          if (route) router.navigate(route);
        }

        return isAuthenticated === allowNavigationIfAuthenticationIs;
      }),
      catchError(() => {
        if (route) router.navigate(route);

        return of(allowNavigationOnError);
      })
    );
  };
}
