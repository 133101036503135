import { Component, EventEmitter, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AppService } from '../../services/app.service';

import { TranslationService } from '../../services/translation.service';
import { DashboardService, IDashboardOffer } from '../../services/dashboard.service';

@Component({
  selector: 'portal-open-offers',
  templateUrl: './open-offers.component.html',
  styleUrls: ['./open-offers.component.scss'],
})
export class OpenOffersComponent implements OnDestroy {
  public displayedColumns: string[] = ['DocumentNo', 'DocumentDescription', 'DocumentDate', 'DocumentSalesPrice', 'DocumentUUID'];
  @Input() public isMobile: boolean;
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public openOffers: IDashboardOffer[];
  public showSkeleton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    public translationService: TranslationService,
    private _dashboardService: DashboardService,
    public appService: AppService
  ) {
    this._dashboardService
      .getDashboardOffers()
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((openOffers: IDashboardOffer[]) => {
          this.openOffers = openOffers;
          this.showSkeleton$.next(false);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
