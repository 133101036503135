import { MatDialog } from '@angular/material/dialog';
import { InvoiceEmailProclamationComponent } from '../invoice-email-proclamation/invoice-email-proclamation/invoice-email-proclamation.component';
import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { ENVIRONMENT } from '../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { BannerService } from '../services/banner.service';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { FeedbackService } from '../services/feedback.service';
import { GettingStartedService } from '../services/getting-started.service';
import { SettingsService } from '../services/settings.service';
import { TranslationService } from '../services/translation.service';
import { UserService } from '../services/user.service';
import { AppService } from '../services/app.service';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'portal-routing',
  templateUrl: './routing.component.html',
  styleUrls: ['./routing.component.scss'],
})
export class RoutingComponent implements AfterViewInit, OnDestroy {
  @ViewChild('outletWrapper') public outletWrapper: ElementRef;
  public environment = ENVIRONMENT;
  public location = window.location;
  @ViewChild('bannerViewRefAnchor', { read: ViewContainerRef, static: true }) private _bannerViewRefAnchor: ViewContainerRef;
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public contentLoaded = false;

  constructor(
    public authenticationService: AuthenticationService,
    public breadcrumbService: BreadcrumbService,
    public translationService: TranslationService,
    public userService: UserService,
    public platform: Platform,
    private _router: Router,
    private _renderer: Renderer2,
    private _cd: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    private _ngZone: NgZone,
    public settingsService: SettingsService,
    private _gettingStartedService: GettingStartedService,
    private _bannerService: BannerService,
    public feedbackService: FeedbackService,
    private _matDialog: MatDialog,
    public appService: AppService,
    public loadingService: LoadingService
  ) {
    if (this.authenticationService.accessedUrl.includes(this._activatedRoute.snapshot.url.join('/'))) {
      this.authenticationService.clearAccessedUrl();
    }

    this.breadcrumbService.reset();
    this.breadcrumbService.createBreadcrumbs(this._activatedRoute.snapshot.root);

    this.authenticationService.authenticationState$
      .pipe(
        takeUntil(this._destroyEmitter),
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        tap(() => {
          if (this.settingsService.settingsChange$.value?.FirstVisit) {
            this._gettingStartedService.showGettingStartedOverlay();
          } else if (
            this.authenticationService.loginPageVisited &&
            !this.settingsService.settingsChange$.value?.InvoiceEMailNotificationDismissed &&
            this.userService.customer$.value?.CustomerInvoiceEMailAddresses?.length === 0 &&
            userService.currentUser$?.value?.UserPermissionNames.includes('ManageCustomerInformation')
          ) {
            this._matDialog.open(InvoiceEmailProclamationComponent, { hasBackdrop: true, closeOnNavigation: true });
          }
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.emit();
  }

  public ngAfterViewInit(): void {
    this.contentLoaded = true;

    this._router.events
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((routerEvent) => {
          if (routerEvent instanceof NavigationStart) {
            this.loadingService.isLoading$.next(
              !(
                Object.prototype.hasOwnProperty.call(this._activatedRoute.snapshot.firstChild?.data || {}, 'skipLoading') &&
                this._activatedRoute.snapshot.firstChild.data.skipLoading
              ) &&
                !(
                  Object.prototype.hasOwnProperty.call(this._router.getCurrentNavigation()?.extras?.state || {}, 'skipLoading') &&
                  this._router.getCurrentNavigation().extras.state.skipLoading
                )
            );

            this._cd.detectChanges();

            this._bannerService.removeActiveBanner();

            if (!this._router.getCurrentNavigation().extras.state?.skipFade) {
              this._renderer.addClass(this.outletWrapper.nativeElement, 'faded');
            }
          } else if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
            this._renderer.removeClass(this.outletWrapper.nativeElement, 'faded');
            this.loadingService.isLoading$.next(false);
            this._cd.detectChanges();

            if (
              routerEvent instanceof NavigationError &&
              (routerEvent.error.name === 'ChunkLoadError' ||
                routerEvent.error.toString().includes('ChunkLoadError') ||
                /Loading chunk \d+ failed/.test(routerEvent.error))
            ) {
              this._bannerService
                .showBanner({
                  type: 'Error',
                  icon: 'error',
                  title: this.translationService.translations.error.ChunkLoadError.toString(),
                  retryActionDescription: this.translationService.translations.action.TryAgain.toString(),
                })
                .instance.retryAction.pipe(
                  takeUntil(this._destroyEmitter),
                  take(1),
                  tap(() => this._router.navigate([routerEvent.url]))
                );
            }

            this.breadcrumbService.reset();
            this.breadcrumbService.createBreadcrumbs(this._activatedRoute.snapshot.root);
          }
        })
      )
      .subscribe();

    this._cd.detectChanges();
  }
}
