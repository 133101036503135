import { OverlayRef } from '@angular/cdk/overlay';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, HostListener, QueryList, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { concat, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap } from 'rxjs/operators';
import { InvoiceEMailEditFormComponent } from '../invoice-email-proclamation/invoice-email-edit-form/invoice-email-edit-form.component';
import { AppService, DeviceType, Orientation } from '../services/app.service';
import { AuthenticationService } from '../services/authentication.service';
import { CustomerService } from '../services/customer.service';
import { ErrorHandlingService } from '../services/error-handling.service';
import { ISettings, SettingsService, ThemeClass } from '../services/settings.service';
import { TranslationService } from '../services/translation.service';
import { UnsavedChangesService } from '../services/unsaved-changes.service';
import { IUser, UserService } from '../services/user.service';
import { UserEditFormComponent } from '../user-edit-form/user-edit-form.component';

@Component({
  selector: 'portal-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
  providers: [{ provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } }],
})
export class GettingStartedComponent implements AfterViewInit {
  public overlayRef: OverlayRef;
  public loading = false;
  public permissionToManageInformation$: Observable<boolean> = this.userService.currentUser$.pipe(
    filter((currentUser) => !!currentUser),
    map((currentUser) => {
      return currentUser.UserPermissionNames.includes('ManageInformation');
    })
  );
  public permissionToManageCustomerInformation$: Observable<boolean> = this.userService.currentUser$.pipe(
    filter((currentUser) => !!currentUser),
    map((currentUser) => {
      return currentUser.UserPermissionNames.includes('ManageCustomerInformation');
    })
  );
  public readonly hasCustomerInvoiceEMailAddresses: boolean = this.userService.customer$.value?.CustomerInvoiceEMailAddresses?.length > 0;
  @ViewChildren('userEditFormComponent', { read: UserEditFormComponent }) public userEditFormComponent: QueryList<UserEditFormComponent>;
  @ViewChildren('invoiceEMailEditFormComponent') public invoiceEMailEditFormComponent: QueryList<InvoiceEMailEditFormComponent>;
  @HostBinding('class.mobile') public mobile;
  @HostListener('document:keydown', ['$event']) private _handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  constructor(
    public settingsService: SettingsService,
    public translationService: TranslationService,
    public userService: UserService,
    public appService: AppService,
    private _cd: ChangeDetectorRef,
    private _matSnackBar: MatSnackBar,
    private _customerService: CustomerService,
    private _unsavedChangesService: UnsavedChangesService,
    private _errorHandlingService: ErrorHandlingService,
    private _authenticationService: AuthenticationService
  ) {
    this.appService.typeAndOrientationChange$.subscribe(({ type, orientation }) => {
      this.mobile = type === DeviceType.HANDSET || (type === DeviceType.TABLET && orientation === Orientation.PORTRAIT);
    });
  }

  public close(): void {
    of(this._unsavedChangesService.hasUnsavedChanges())
      .pipe(
        switchMap((hasChanges: boolean) => {
          if (hasChanges) {
            return this._unsavedChangesService.confirmUnsavedChanges();
          } else {
            return of(true);
          }
        }),
        filter((confirmedClose) => confirmedClose),
        switchMap(() => {
          this.loading = true;
          const settings: ISettings = this.settingsService.settingsChange$.value;
          settings.FirstVisit = false;

          return this._errorHandlingService
            .handleErrorWithBannerAndRetry<void>({
              endpoint: this.settingsService.setSettings([
                {
                  SettingName: 'FirstVisit',
                  SettingValue: false,
                },
              ]),
              propertyModification: () => {
                this.overlayRef.detach();
              },
              bannerTitle: this.translationService.translations.error.SaveFirstVisitSettingFailed.toString(),
              bannerSubtitle: '',
              sideSheetComponent: null,
              onDismiss: null,
              rethrowError: true,
            })
            .pipe(
              catchError((error) => throwError(() => error)),
              finalize(() => {
                this.loading = false;

                if (this._authenticationService.authenticationData?.IsReadOnlySession) {
                  this.overlayRef.detach();
                }
              })
            );
        })
      )
      .subscribe();
  }

  public saveUserChange(): void {
    this.loading = true;

    const updates$: Observable<void>[] = [this.userService.setCurrentUser(this.userEditFormComponent.first?.editUserForm.value)];

    if (this.invoiceEMailEditFormComponent.first) {
      updates$.push(this._customerService.saveInvoiceEMailAddresses(this.invoiceEMailEditFormComponent.first.parentForm.value));
    }

    updates$.push(this.settingsService.setSettings([{ SettingName: 'FirstVisit', SettingValue: false }]));

    this._errorHandlingService
      .handleErrorWithBannerAndRetry<unknown>({
        endpoint: concat(...updates$).pipe(
          switchMap(() => {
            this.loading = false;
            this.userEditFormComponent.first?.editUserForm.enable();
            this.userEditFormComponent.first?.editUserForm.markAsPristine();

            return this._errorHandlingService.handleErrorWithBannerAndRetry<IUser>({
              endpoint: this.userService.getCurrentUser(),
              propertyModification: (user: IUser) => {
                const newLocale = user.UserLanguageDescription.toLowerCase().startsWith('de') ? 'de' : 'en';

                if (newLocale !== this.translationService.locale) {
                  this.translationService.setLocale(newLocale);
                }

                this.userService.currentUser$.next(user);
                this._matSnackBar.open(this.translationService.translations.common.ChangesSaveSuccess.toString(), null, { duration: 5000 });
                this.overlayRef.detach();
              },
              bannerTitle: this.translationService.translations.common.ChangesSaveFailure.toString(),
            });
          })
        ),
        propertyModification: null,
        bannerTitle: this.translationService.translations.common.ChangesSaveFailure.toString(),
      })
      .subscribe(() => {
        this.loading = false;
        this.userEditFormComponent.first?.editUserForm.enable();
        this.userEditFormComponent.first?.editUserForm.markAsPristine();
      });
  }

  public ngAfterViewInit(): void {
    this.userEditFormComponent.first?.editUserForm.markAllAsTouched();
    this._cd.detectChanges();
  }

  public saveTheme(value: ThemeClass): void {
    this._errorHandlingService
      .handleErrorWithBannerAndRetry<void>({
        endpoint: this.settingsService.setSettings([{ SettingName: 'DarkThemeEnabled', SettingValue: value === 'dark-theme' }]),
        propertyModification: () => this.settingsService.setThemeClass(value),
        bannerTitle: this.translationService.translations.error.SetThemeFailed.toString(),
      })
      .subscribe();
  }
}
