<div class="theme-wrapper" [class.mobile]="(appService.isDesktop$ | async) === false">
  <div class="theme-container" [ngClass]="theme === 'light-theme' ? 'light-theme' : 'dark-theme'">
    <div class="toolbar">
      <div class="toolbar-logo"></div>
    </div>
    <div class="content">
      <div class="inner-container">
        @for (number of [1, 2, 3]; track number) {
          <div class="inner-container-element">
            <div class="element-button"></div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
