import { Component, Input } from '@angular/core';
import { AppService } from '../../services/app.service';
import { IDashboardOrder } from '../../services/dashboard.service';

import { TranslationService } from '../../services/translation.service';

enum State {
  FOLLOWING,
  CURRENT,
  PREVIOUS,
}

@Component({
  selector: 'portal-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
})
export class OrderStatusComponent {
  @Input() public order: IDashboardOrder;
  public orderState: typeof State = State;

  constructor(
    public translationService: TranslationService,
    public appService: AppService
  ) {}
}
