<portal-dev-navigation></portal-dev-navigation>
<portal-breadcrumbs></portal-breadcrumbs>

<mat-sidenav-container>
  <mat-sidenav-content id="sidenavContent">
    <div #outletWrapper class="outlet-wrapper">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
