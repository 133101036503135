import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseDialogModule } from '../../base-dialog/base-dialog.module';
import { MarkerSketchModule } from '../../marker-sketch/marker-sketch.module';
import { LoginDialogComponent } from './login-dialog.component';
import { MatButton } from '@angular/material/button';
import { OTPInputsComponent } from '../../otp-inputs/otp-inputs.component';

@NgModule({
  declarations: [LoginDialogComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    BaseDialogModule,
    MarkerSketchModule,
    MatDialogModule,
    MatButton,
    OTPInputsComponent,
  ],
})
export class LoginDialogModule {}
