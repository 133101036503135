import { PlatformLocation } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { skipWhile, tap } from 'rxjs/operators';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './cart/checkout/checkout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeactivatedPageComponent } from './deactivated-page/deactivated-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FeedbackPageComponent } from './feedback/feedback-page/feedback-page.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { authenticationGuard } from './guards/authentication.guard';
import { checkoutGuard } from './guards/checkout.guard';
import { deactivatedGuard } from './guards/deactivated.guard';
import { forbiddenGuard } from './guards/forbidden.guard';
import { maintenanceGuard } from './guards/maintenance.guard';
import { unsavedChangesGuard } from './guards/unsaved-changes.guard';
import { withUnacceptedTosGuard } from './guards/with-unaccepted-tos.guard';
import { LogoutComponent } from './logout/logout.component';
import { RoutingComponent } from './routing/routing.component';
import { AuthenticationService } from './services/authentication.service';
import { BreadcrumbService } from './services/breadcrumb.service';
import { TRANSLATION_MAP } from './services/translation.service';
import { PERMISSIONS_RESOLVE } from './services/user-permission.service';
import { PunchOutCatalogService } from './services/punch-out-catalog.service';
import { RouteNotFoundComponent } from './route-not-found/route-not-found.component';
import { getUserBeforeNavigationGuard } from './guards/get-user-before-navigation.guard';
import { revisionGuard } from './guards/revision.guard';
import { latestTosGuard } from './guards/latest-tos.guard';
import { userAuthenticationGuard } from './guards/user-authentication.guard';

export const RERUN_ERROR_GUARD = (): boolean => true;

const routes: Routes = [
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance-page/maintenance-page.module').then((m) => m.MaintenancePageModule),
    canActivate: [maintenanceGuard()],
  },
  {
    path: 'oci',
    loadChildren: () => import('./oci/oci.module').then((m) => m.OciModule),
  },
  {
    path: 'cxml',
    loadChildren: () => import('./cxml/cxml.module').then((m) => m.CxmlModule),
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
    canActivate: [userAuthenticationGuard({ allowNavigationIfAuthenticationIs: false, route: ['/'] })],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login-component/login.module').then((m) => m.LoginModule),
    canActivate: [userAuthenticationGuard({ allowNavigationIfAuthenticationIs: false, route: ['/'] })],
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [userAuthenticationGuard({ allowNavigationIfAuthenticationIs: true, route: ['/login'] })],
    data: { breadcrumb: { label: TRANSLATION_MAP.titles.Logout } },
  },
  {
    path: 'termsofservice',
    loadChildren: () => import('./tos/tos.module').then((m) => m.TosModule),
    canActivate: [withUnacceptedTosGuard()],
  },
  {
    path: 'errorRedirect',
    redirectTo: 'error',
  },
  {
    path: 'password',
    loadChildren: () => import('./password/password.module').then((m) => m.PasswordModule),
    canActivate: [userAuthenticationGuard({ allowNavigationIfAuthenticationIs: false, route: ['/'] })],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    canActivate: [userAuthenticationGuard({ allowNavigationIfAuthenticationIs: false, allowNavigationOnError: true, route: ['/'] })],
    runGuardsAndResolvers: RERUN_ERROR_GUARD,
    data: { breadcrumb: { label: TRANSLATION_MAP.titles.Error } },
  },
  {
    path: 'deactivated',
    component: DeactivatedPageComponent,
    canActivate: [deactivatedGuard()],
    data: { breadcrumb: { label: TRANSLATION_MAP.titles.Deactivated } },
  },
  {
    path: 'cart',
    component: CartComponent,
    canDeactivate: [unsavedChangesGuard()],
    data: { breadcrumb: { label: TRANSLATION_MAP.titles.Cart } },
  },
  {
    path: '',
    canActivate: [authenticationGuard()],
    component: RoutingComponent,
    resolve: [PERMISSIONS_RESOLVE],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'assets',
        loadChildren: () => import('./assets/asset.module').then((m) => m.AssetModule),
      },
      {
        path: 'billingaccounts',
        loadChildren: () => import('./billing-accounts/billing-accounts.module').then((m) => m.BillingAccountsModule),
      },
      {
        path: 'licenses',
        loadChildren: () => import('./licenses/license.module').then((m) => m.LicenseModule),
      },
      {
        path: 'cases',
        redirectTo: 'servicetickets',
        pathMatch: 'full',
      },
      {
        path: 'cases/:key',
        redirectTo: 'servicetickets/:key',
      },
      {
        path: 'requests',
        redirectTo: 'servicetickets',
        pathMatch: 'full',
      },
      {
        path: 'requests/:key',
        redirectTo: 'servicetickets/:key',
      },
      {
        path: 'servicetickets',
        loadChildren: () => import('./service-tickets/service-ticket.module').then((m) => m.ServiceTicketModule),
      },
      {
        path: 'servicevouchers',
        loadChildren: () => import('./service-vouchers/service-vouchers.module').then((m) => m.ServiceVouchersModule),
      },
      {
        path: 'documents/agreements',
        redirectTo: 'documents/baskets',
      },
      {
        path: 'documents/baskets',
        loadChildren: () => import('./baskets/basket.module').then((m) => m.BasketModule),
      },
      {
        path: 'documents',
        loadChildren: () => import('./documents/document.module').then((m) => m.DocumentModule),
      },
      {
        path: 'credits',
        loadChildren: () => import('./credits/credits.module').then((m) => m.CreditsModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'account/settings',
        pathMatch: 'full',
        redirectTo: 'account/',
      },
      {
        path: 'account',
        pathMatch: 'full',
        redirectTo: 'account/',
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
        canDeactivate: [unsavedChangesGuard()],
        data: { skipLoading: true },
      },
      {
        path: 'users/list',
        pathMatch: 'full',
        redirectTo: 'users/',
      },
      {
        path: 'users',
        pathMatch: 'full',
        redirectTo: 'users/',
      },
      {
        path: 'users',
        loadChildren: () => import('./users/user.module').then((m) => m.UserModule),
        canDeactivate: [unsavedChangesGuard()],
        data: { breadcrumb: { label: TRANSLATION_MAP.titles.Users } },
      },
      {
        path: 'addresses/list',
        pathMatch: 'full',
        redirectTo: 'addresses/',
      },
      {
        path: 'addresses',
        pathMatch: 'full',
        redirectTo: 'addresses/',
      },
      {
        path: 'addresses',
        loadChildren: () => import('./addresses/address.module').then((m) => m.AddressModule),
        canDeactivate: [unsavedChangesGuard()],
        data: { breadcrumb: { label: TRANSLATION_MAP.titles.Addresses } },
      },
      {
        path: 'costcenters/list',
        pathMatch: 'full',
        redirectTo: 'costcenters/',
      },
      {
        path: 'costcenters',
        pathMatch: 'full',
        redirectTo: 'costcenters/',
      },
      {
        path: 'costcenters',
        loadChildren: () => import('./cost-centers/cost-center.module').then((m) => m.CostCenterModule),
        canDeactivate: [unsavedChangesGuard()],
        data: { breadcrumb: { label: TRANSLATION_MAP.titles.CostCenters } },
      },
      {
        path: 'profiles/list',
        pathMatch: 'full',
        redirectTo: 'profiles/',
      },
      {
        path: 'profiles',
        pathMatch: 'full',
        redirectTo: 'profiles/',
      },
      {
        path: 'profiles',
        loadChildren: () => import('./user-profiles/user-profile.module').then((m) => m.UserProfileModule),
        canDeactivate: [unsavedChangesGuard()],
        data: { breadcrumb: { label: TRANSLATION_MAP.titles.Profiles } },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { breadcrumb: { label: TRANSLATION_MAP.common.Dashboard } },
        canDeactivate: [unsavedChangesGuard()],
      },
      {
        path: 'files/list',
        pathMatch: 'full',
        redirectTo: 'files/',
      },
      {
        path: 'files',
        pathMatch: 'full',
        redirectTo: 'files/',
      },
      {
        path: 'files',
        data: { breadcrumb: { label: TRANSLATION_MAP.titles.UserFiles } },
        loadChildren: () => import('./user-files/user-files.module').then((m) => m.UserFilesModule),
        canDeactivate: [unsavedChangesGuard()],
      },
      {
        path: 'feedback',
        component: FeedbackPageComponent,
        data: { breadcrumb: { label: TRANSLATION_MAP.titles.Feedback } },
        canDeactivate: [unsavedChangesGuard()],
      },
      {
        path: 'forbidden',
        component: ForbiddenComponent,
        canActivate: [forbiddenGuard()],
        data: { breadcrumb: { label: TRANSLATION_MAP.titles.Forbidden } },
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [checkoutGuard(), revisionGuard(), latestTosGuard()],
        data: { breadcrumb: { label: TRANSLATION_MAP.titles.Checkout } },
      },
      {
        path: 'openpositions',
        loadChildren: () => import('./open-positions-list/open-positions-list.module').then((m) => m.OpenPositionsListModule),
      },
    ],
  },
  {
    path: '404',
    component: RouteNotFoundComponent,
    canActivate: [getUserBeforeNavigationGuard()],
    data: { breadcrumb: { label: TRANSLATION_MAP.titles.RouteNotFound } },
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AppRoutingModule {
  constructor(
    private _breadcrumbService: BreadcrumbService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _punchOutCatalogService: PunchOutCatalogService,
    private _authenticationService: AuthenticationService,
    private _platformLocation: PlatformLocation
  ) {
    if (this._platformLocation.pathname.includes('/oci')) {
      this._punchOutCatalogService.isRequestingOCI = true;
    } else if (this._platformLocation.pathname.includes('/cxml')) {
      this._punchOutCatalogService.isRequestingCXML = true;
    }

    this._router.events
      .pipe(
        skipWhile(() => this._authenticationService.authenticationData && !this._authenticationService.authenticationData?.Authenticated),
        tap((routerEvent) => {
          if (routerEvent instanceof NavigationEnd) {
            this._breadcrumbService.createBreadcrumbs(this._activatedRoute.snapshot);
          }
        })
      )
      .subscribe();
  }
}
