import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { DashboardService, IDashboardOpenPosition } from '../../services/dashboard.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'portal-open-positions',
  templateUrl: './open-positions.component.html',
  styleUrls: ['./open-positions.component.scss'],
})
export class OpenPositionsComponent implements OnDestroy {
  public displayedColumns: string[] = [
    'DocumentNo',
    'DocumentPositionStateDescription',
    'DocumentPositionItemDescription',
    'DocumentPositionExpectedDeliveryDate',
    'DocumentPositionOpenQuantityTotal',
  ];
  public openPositions: IDashboardOpenPosition[] = [];
  public slicedOpenPositions: IDashboardOpenPosition[];
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public showSkeleton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    public translationService: TranslationService,
    private _dashboardService: DashboardService,
    public appService: AppService
  ) {
    this._dashboardService
      .getDashboardOpenPositions()
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((openPositions: IDashboardOpenPosition[]) => {
          this.openPositions = openPositions;

          if (this.openPositions?.length >= 6) {
            this.slicedOpenPositions = this.openPositions.slice(0, 6);
          } else {
            this.slicedOpenPositions = this.openPositions;
          }
          this.showSkeleton$.next(false);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
