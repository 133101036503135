<div class="flex-column-container">
  <div class="error-box">
    <mat-icon [color]="'warn'" class="error-icon" [svgIcon]="'enthus-vpn_lock'"></mat-icon>
    <p>{{ translationService.translations.common.NoPortalAccessError }}</p>
    <portal-phone-or-email
      [clerkName]="(authenticationService.accountManager$ | async)?.AccountManagerName"
      [phone]="(authenticationService.accountManager$ | async)?.AccountManagerPhone"
      [email]="(authenticationService.accountManager$ | async)?.AccountManagerEMailAddress"
    ></portal-phone-or-email>
  </div>
</div>
