@if ((feedbackService.loadingService.isLoading$ | async) === false) {
  <portal-marker-sketch [infoMessage]="feedbackTitle" [svgIcon]="icon">
    <portal-feedback
      [infoSubtext]="infoSubtext"
      [infoText]="infoText"
      [showAlternativeContactOptions]="showAlternativeContactOptions"
      class="grey-form-field-background"
    >
      <button
        (click)="sendFeedback({ action: action, showBackdrop: showBackdrop })"
        [disabled]="feedbackService.feedbackForm.invalid || (feedbackService.loadingService.isLoading$ | async) === true"
        color="primary"
        mat-raised-button
      >
        <mat-icon [svgIcon]="'enthus-send'"></mat-icon>&nbsp;{{ translationService.translations.action.Send }}
      </button>
    </portal-feedback>
  </portal-marker-sketch>
}
