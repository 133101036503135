import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ISettings, SettingsService } from '../services/settings.service';

export function authenticationGuard(): CanActivateFn {
  return (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);
    const settingsService = inject(SettingsService);

    authenticationService.setAccessedUrl(state.url);

    return authenticationService.isUserAuthenticated().pipe(
      catchError(() => of(false)),
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          return settingsService.getSettings();
        } else {
          return of(null);
        }
      }),
      map((settings) => {
        if (settings) {
          const mappedSettings: ISettings = {} as ISettings;

          for (const setting of settings) {
            mappedSettings[setting.SettingName] = setting.SettingValue;
          }

          settingsService.settingsChange$.next(mappedSettings);
          settingsService.setTheme(mappedSettings.DarkThemeEnabled ? 'dark-theme' : 'light-theme');

          if (
            (!sessionStorage.getItem('isE2ESession') &&
              authenticationService.loggedInViaPortal &&
              !authenticationService.authenticationData?.HasAcceptedLatestTermsAndConditions) ||
            (authenticationService.authenticationData.Authenticated && !authenticationService.authenticationData?.HasAcceptedLatestTermsAndConditions)
          ) {
            router.navigate(['/termsofservice']);
            return false;
          } else {
            return true;
          }
        } else {
          router.navigate(['/login']);

          return false;
        }
      })
    );
  };
}
