import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService, IAccountManager } from '../services/authentication.service';
import { Observable } from 'rxjs';

export function deactivatedGuard(): CanActivateFn {
  return (): Observable<boolean> => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);

    return authenticationService.accountManager$.pipe(
      map((contact: IAccountManager) => {
        if (!contact) {
          router.navigate(['/']);

          return false;
        }
        return true;
      })
    );
  };
}
