import { Component, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AppService } from '../services/app.service';
import { AuthenticationService } from '../services/authentication.service';
import { DashboardService, IDashboardOrder } from '../services/dashboard.service';
import { ErrorHandlingService } from '../services/error-handling.service';
import { TranslationService } from '../services/translation.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'portal-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  @ViewChild('dashboard') public dashboard: ElementRef;
  public location = window.location;
  public gridListColumnCount = 3;
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public dashboardOrders: BehaviorSubject<IDashboardOrder[]> = new BehaviorSubject<IDashboardOrder[]>(null);
  public ordersToApprove$: BehaviorSubject<IDashboardOrder[]> = new BehaviorSubject<IDashboardOrder[]>([]);

  constructor(
    public translationService: TranslationService,
    public dashboardService: DashboardService,
    private _errorHandlingService: ErrorHandlingService,
    public authenticationService: AuthenticationService,
    public appService: AppService,
    private _userService: UserService
  ) {
    this._errorHandlingService
      .handleErrorWithBannerAndRetry<IDashboardOrder[]>({
        endpoint: this.dashboardService.getDashboardOrders().pipe(
          takeUntil(this._destroyEmitter),
          tap((orders: IDashboardOrder[]) => {
            this.dashboardOrders.next(orders);
            const ordersToApprove = orders.filter((order) => {
              return (
                order.DocumentCostCenterResponsibleContactUUID === this._userService.currentUser$.value.UserUUID &&
                order.DocumentStateName === 'approval_pending'
              );
            });

            this.ordersToApprove$.next(ordersToApprove);
          })
        ),
        propertyModification: null,
        bannerTitle: this.translationService.translations.error.FetchDashboardFailed.toString(),
      })
      .subscribe();

    this.gridListColumnCount = DashboardComponent._calculateColumnCount();
  }

  public resize(event: Event): void {
    this.gridListColumnCount = DashboardComponent._calculateColumnCount(event);
  }

  private static _calculateColumnCount(event?: Event): number {
    const calculatedColumnCount = Math.floor((event ? (event.target as Window).innerWidth : window.innerWidth) / 360) - 1;

    return calculatedColumnCount > 3 ? 3 : calculatedColumnCount;
  }
}
