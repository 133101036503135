import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmService } from '../confirm/confirm.service';
import { ICart, ICartGeneric, ICartOfferArchivePosition, IOfferCartResponse, IOrderCartResponse, ISetCartRequest } from '../interfaces/cart.interface';
import { IGenericPosition } from '../interfaces/generic-position.interface';
import { DialogService } from './dialog.service';
import { DocumentPositionSetConfigurationService } from './document-position-set-configuration.service';
import { ErrorHandlingService } from './error-handling.service';
import { GenericPositionService } from './generic-position.service';
import { PunchOutCatalogService } from './punch-out-catalog.service';
import { TranslationService } from './translation.service';
import { VatService } from './vat.service';
import { BaseCartService } from './base-cart.service';
import { LoadingService } from './loading.service';
import { DataLayerService } from './data-layer.service';

interface ISetPrice {
  PositionSalesPrice: number;
  PositionSalesPriceInclusiveVAT: number;
}

/**
 * NEVER ! inject this cart service subclass, ALWAYS ! inject BaseCartService.
 * The cart service providers and the cart service factory will decide which cart subclass to use.
 */
export class OfflineCartService extends BaseCartService {
  constructor(
    protected override _translationService: TranslationService,
    private _httpClient: HttpClient,
    protected override _genericPositionsService: GenericPositionService,
    protected override _errorHandlingService: ErrorHandlingService,
    private _matSnackBar: MatSnackBar,
    protected override _dialogService: DialogService,
    protected override _confirmService: ConfirmService,
    private _punchOutCatalogService: PunchOutCatalogService,
    protected override _documentPositionSetConfigurationService: DocumentPositionSetConfigurationService,
    protected override _vatService: VatService,
    protected override _loadingService: LoadingService,
    protected override _dataLayerService: DataLayerService
  ) {
    super(
      _translationService,
      _errorHandlingService,
      _genericPositionsService,
      _dialogService,
      _confirmService,
      _documentPositionSetConfigurationService,
      _vatService,
      _loadingService,
      _dataLayerService
    );

    if (sessionStorage.getItem('cart')) {
      this.cart$.next(JSON.parse(sessionStorage.getItem('cart'))); // if cart object gets properties with such types as for example date or function then a custom JSON data serializer should be implemented
    }

    this._handleCartChanges((cart, message) => {
      return of({
        CustomerPaymentConditionDescription: null,
        CartRemark: cart.CartRemark,
        CustomerCanUsePayPal: false,
        CartReference: cart.CartReference,
        CartInvoiceAddressUUID: cart.CartInvoiceAddressUUID,
        CartDescription: cart.CartDescription,
        CartDeliveryAddressUUID: cart.CartDeliveryAddressUUID,
        CartDeliveryDate: cart.CartDeliveryDate,
        CartDeliveryDateTypeID: cart.CartDeliveryDateTypeID,
        CartPositions: this._copyCartPositions(cart),
      } as ICartGeneric).pipe(
        tap((updatedCart: ICartGeneric) => {
          this.cart$.next(updatedCart);
          sessionStorage.setItem('cart', JSON.stringify(updatedCart));
          this._loadingService.isLoading$.next(false);

          if (message && !this.skipSnackBar) {
            this._matSnackBar.open(message, 'OK', { duration: 10000 });
          }

          this.skipSnackBar = false;
        })
      );
    }).subscribe();

    if (this._punchOutCatalogService.isRequestingCXML) {
      this.cart$.next({
        CartDeliveryAddressAdditionalInformation: '',
        CartDescription: '',
        CartInvoiceAddressAdditionalInformation: '',
        CartPositions: [],
        CartReference: '',
        CartRemark: '',
        CustomerCanUsePayPal: false,
        CustomerPaymentConditionDescription: { de: '', en: '' },
        PaymentHasTax: false,
        CartDeliveryDate: '',
        CartDeliveryDateTypeID: undefined,
      });
    }
  }

  private _calculateSetPrices(positions: Partial<IGenericPosition>[]): ISetPrice {
    let setPositionSalesPrice = 0;
    let setPositionSalesPriceInclusiveVAT = 0;

    positions.forEach((position) => {
      setPositionSalesPrice += position.PositionSalesPrice * position.PositionItemQuantity;
      setPositionSalesPriceInclusiveVAT += position.PositionSalesPriceInclusiveVAT * position.PositionItemQuantity;
    });

    return {
      PositionSalesPrice: setPositionSalesPrice,
      PositionSalesPriceInclusiveVAT: setPositionSalesPriceInclusiveVAT,
    };
  }

  // eslint-disable-next-line max-lines-per-function
  private _copyCartPositions(cart: Partial<ISetCartRequest>): Partial<IGenericPosition>[] {
    const cartPositions: Partial<IGenericPosition>[] = [];

    // eslint-disable-next-line max-lines-per-function
    cart.CartOfferArchivePositions.forEach((offerArchivePosition: ICartOfferArchivePosition) => {
      const flatDocumentPositionWithAlternatives: Partial<IGenericPosition>[] = [];

      this._punchOutCatalogService.basketWithPositions.DocumentPositions.forEach((documentPosition) => {
        if (documentPosition.PositionIsAlternative) {
          flatDocumentPositionWithAlternatives.push(...documentPosition.PositionAlternativePositions);
        } else {
          flatDocumentPositionWithAlternatives.push(documentPosition);
        }
      });

      const documentPosition: Partial<IGenericPosition> = flatDocumentPositionWithAlternatives.find(
        (genericDocumentPosition) => genericDocumentPosition.PositionId === offerArchivePosition.CartOfferArchivePositionId
      );

      const childPositions: Partial<IGenericPosition>[] = [];

      // Handle set positions -----------------------------
      let setPrice: ISetPrice = {
        PositionSalesPrice: documentPosition.PositionSalesPrice,
        PositionSalesPriceInclusiveVAT: documentPosition.PositionSalesPriceInclusiveVAT,
      };

      if (documentPosition.PositionIsSet) {
        const flatChildPositionsWithAlternatives: Partial<IGenericPosition>[] = [];

        documentPosition.PositionChildPositions.forEach((childPosition) => {
          if (childPosition.PositionIsAlternative) {
            flatChildPositionsWithAlternatives.push(...childPosition.PositionAlternativePositions);
          } else {
            flatChildPositionsWithAlternatives.push(childPosition);
          }
        });

        if (
          documentPosition.PositionHasAlternativeChildren ||
          documentPosition.PositionHasOptionalChildren ||
          documentPosition.PositionHasCustomizableQuantities
        ) {
          documentPosition.PositionIsConfigurable = true;
        }

        // Take only the children that are in the configuration as well
        const setChildren = flatChildPositionsWithAlternatives.filter((childPosition) => {
          return offerArchivePosition.CartOfferArchivePositionSetPositionQuantities.map((setPositionQuantity) => setPositionQuantity.PositionId).includes(
            childPosition.PositionId
          );
        });

        setChildren.forEach((setChild) => {
          const customSetPositionQuantity = documentPosition.PositionSetPositionQuantities?.find(
            (setPositionQuantity) => setPositionQuantity.PositionId === setChild.PositionId
          )?.Quantity;

          const customSavedQuantity = offerArchivePosition.CartOfferArchivePositionSetPositionQuantities?.find(
            (setPositionQuantity) => setPositionQuantity.PositionId === setChild.PositionId
          )?.Quantity;

          childPositions.push({
            PositionId: setChild.PositionId,
            PositionItemDescription: setChild.PositionItemDescription,
            PositionItemFreeText: setChild.PositionItemFreeText,
            PositionItemFreeTextDescription: setChild.PositionItemFreeTextDescription,
            PositionMedia: [...setChild.PositionMedia],
            PositionItemQuantity: customSetPositionQuantity || customSavedQuantity || setChild.PositionItemQuantity,

            PositionSalesPrice: setChild.PositionSalesPrice,
            PositionSalesPriceTotal: setChild.PositionSalesPriceTotal * setChild.PositionItemQuantity,
            PositionSalesPriceInclusiveVAT: setChild.PositionSalesPriceInclusiveVAT,
            PositionDeliveryDays: setChild.PositionDeliveryDays,
            PositionItemNo: setChild.PositionItemNo,
            PositionIsSetPosition: setChild.PositionIsSetPosition,
            PositionCurrencyISOSymbol: setChild.PositionCurrencyISOSymbol,
            PositionCategoryPath: setChild.PositionCategoryPath,
          });
        });

        setPrice = this._calculateSetPrices(childPositions);
      }
      // --------------------------------------------------

      cartPositions.push(
        this._dereferencePosition(documentPosition, {
          PositionId: offerArchivePosition.CartOfferArchivePositionId,
          PositionItemQuantity: offerArchivePosition.CartOfferArchivePositionQuantity,
          PositionSetPositionQuantities: offerArchivePosition.CartOfferArchivePositionSetPositionQuantities || [],
          PositionSortOrder: offerArchivePosition.CartPositionSortOrder,
          PositionSalesPrice: setPrice.PositionSalesPrice,
          PositionSalesPriceInclusiveVAT: setPrice.PositionSalesPriceInclusiveVAT,
          PositionSalesPriceTotal: setPrice.PositionSalesPrice * offerArchivePosition.CartOfferArchivePositionQuantity,
          PositionItemVATs: [],
          PositionIsBasketPosition: true,
          PositionIsAlternative: false,
          PositionIsAlternativeActivated: false,
          PositionIsOptional: false,
          PositionIsOptionalActivated: false,
          PositionAccessoryPositions: [],
          PositionAlternativePositions: [],
          PositionHasStates: false,
          PositionIsPurchasable: true,
          PositionChildPositions: [...childPositions],
          PositionHasCustomizableQuantities: documentPosition.PositionHasCustomizableQuantities,
          PositionHasAlternativeChildren: documentPosition.PositionHasAlternativeChildren,
          PositionHasOptionalChildren: documentPosition.PositionHasOptionalChildren,
          PositionIsConfigurable: documentPosition.PositionIsConfigurable,
          PositionOfferNo: this._punchOutCatalogService.basketOfferNo,
          PositionCustomerRemark: offerArchivePosition.CartPositionRemark,
        })
      );
    });

    return cartPositions;
  }

  public getCartFromBackend(): Observable<ICartGeneric> {
    throw new Error('getCartFromBackend is not available for Offline Cart');
  }

  public orderCart(): Observable<IOrderCartResponse> {
    throw new Error('orderCart is not available for Offline Cart');
  }

  public requestOffer(): Observable<IOfferCartResponse> {
    throw new Error('requestOffer is not available for Offline Cart');
  }

  public setCartRemark(): Observable<ICart> {
    throw new Error('setCartRemark is not available for Offline Cart');
  }

  public setCartDescription(): Observable<ICart> {
    throw new Error('setCartDescription is not available for Offline Cart');
  }

  public setCartReference(): Observable<ICart> {
    throw new Error('setCartReference is not available for Offline Cart');
  }

  public setCartPositionRemark(position: Partial<IGenericPosition>, remark: string): Observable<ICart> {
    const cart = this.cart$.value;
    const cartPositionIndex: number = cart.CartPositions.findIndex((cartPosition) => cartPosition.PositionId === position.PositionId);
    if (cartPositionIndex !== -1) {
      cart.CartPositions[cartPositionIndex].PositionCustomerRemark = remark;
    }

    const newCart = {
      CartItems: [],
      CartOfferArchivePositions: [],
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartPositions: [],
      CustomerCanUsePayPal: this.cart$.value.CustomerCanUsePayPal,
      CustomerPaymentConditionDescription: this.cart$.value.CustomerPaymentConditionDescription,
      PaymentHasTax: this.cart$.value.PaymentHasTax,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
    };
    newCart.CartPositions = cart.CartPositions;

    this.cart$.next(newCart);
    sessionStorage.setItem('cart', JSON.stringify(newCart));
    this._loadingService.isLoading$.next(false);

    return of(newCart);
  }

  public setAddresses(): Observable<ICart> {
    throw new Error('setAddresses is not available for Offline Cart');
  }

  public setLicenseAddress(): Observable<ICart> {
    throw new Error('setLicenseAddress is not available for Offline Cart');
  }

  public setCostCenter(): Observable<ICart> {
    throw new Error('setCostCenter is not available for Offline Cart');
  }

  public setCartWarrantySerialNumbers(): Observable<ICart> {
    throw new Error('setCartWarrantySerialNumbers is not available for Offline Cart');
  }

  public setCartInvoiceAddressAsDeliveryAddress(): Observable<ICart> {
    throw new Error('setCartInvoiceAddressAsDeliveryAddress is not available for Offline Cart');
  }

  public setCartDeliveryDateTypeID(): Observable<ICart> {
    throw new Error('setCartDeliveryDateType is not available for Offline Cart');
  }

  public setCartDeliveryDate(): Observable<ICart> {
    throw new Error('setCartDeliveryDate is not available for Offline Cart');
  }

  setCartUsePartialDelivery(): Observable<ICart> {
    throw new Error('usePartialDelivery is not available for Offline Cart');
  }

  protected _setCartSetPositionConfiguration(cartOfferArchivePositions: ICartOfferArchivePosition[]): Observable<ICart> {
    const newCart = {
      CartItems: [],
      CartOfferArchivePositions: cartOfferArchivePositions,
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartPositions: [],
      CustomerCanUsePayPal: this.cart$.value.CustomerCanUsePayPal,
      CustomerPaymentConditionDescription: this.cart$.value.CustomerPaymentConditionDescription,
      PaymentHasTax: this.cart$.value.PaymentHasTax,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    };
    newCart.CartPositions = this._copyCartPositions(newCart);

    this.cart$.next(newCart);
    sessionStorage.setItem('cart', JSON.stringify(newCart));
    this._loadingService.isLoading$.next(false);

    return of(newCart);
  }

  public addOfferPositionsToCart(): Observable<ICartGeneric> {
    throw new Error('addOfferPositionsToCart is not available for Offline Cart');
  }

  public getItemQuantity(): number {
    return 0;
  }

  public saveSortedCart(currentCart: ICartGeneric): Observable<ICartGeneric> {
    return of(currentCart);
  }
}
