import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BaseDialogModule } from '../base-dialog/base-dialog.module';
import { GrossAndNetPriceModule } from '../gross-and-net-price/gross-and-net-price.module';
import { NumberLinkModule } from '../number-link/number-link.module';
import { PDFDownloadModule } from '../pdf-download/pdf-download.module';
import { QuantityKeyModule } from '../quantity-key/quantity-key.module';
import { DashboardService } from '../services/dashboard.service';
import { ShipmentInformationModule } from '../shipment-information/shipment-information.module';
import { ShowMoreModule } from '../show-more/show-more.module';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { DashboardComponent } from './dashboard.component';
import { OnboardingAppointmentComponent } from './onboarding/appointment/appointment.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OpenInvoicesComponent } from './open-invoices/open-invoices.component';
import { OpenOffersComponent } from './open-offers/open-offers.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { OpenPositionsComponent } from './open-positions/open-positions.component';

@NgModule({
  declarations: [
    DashboardComponent,
    OpenInvoicesComponent,
    OpenOffersComponent,
    OrderStatusComponent,
    OnboardingComponent,
    OnboardingAppointmentComponent,
    OpenPositionsComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatListModule,
    PDFDownloadModule,
    NumberLinkModule,
    MatRippleModule,
    MatTabsModule,
    ShipmentInformationModule,
    ReactiveFormsModule,
    ScrollingModule,
    GrossAndNetPriceModule,
    RouterModule,
    ShowMoreModule,
    MatGridListModule,
    BaseDialogModule,
    SkeletonModule,
    QuantityKeyModule,
  ],
  providers: [DashboardService],
})
export class DashboardModule {}
