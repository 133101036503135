import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'portal-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent implements OnInit {
  @Input() public elementsCount = 0;
  @Input() public elementHeight: number;
  @Input() public showDivider = false;
  public elements: string[] = [];

  ngOnInit(): void {
    if (this.elementsCount > 0) {
      for (let i = 0; i < this.elementsCount; i++) {
        this.elements.push('');
      }
    }
  }
}
