import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmService } from '../confirm/confirm.service';
import { AuthenticationService } from '../services/authentication.service';
import { BaseCartService } from '../services/base-cart.service';
import { CartService } from '../services/cart.service';
import { DialogService } from '../services/dialog.service';
import { DocumentPositionSetConfigurationService } from '../services/document-position-set-configuration.service';
import { ErrorHandlingService } from '../services/error-handling.service';
import { GenericPositionService } from '../services/generic-position.service';
import { OfflineCartService } from '../services/offline-cart.service';
import { PunchOutCatalogService } from '../services/punch-out-catalog.service';
import { TranslationService } from '../services/translation.service';
import { VatService } from '../services/vat.service';
import { LoadingService } from '../services/loading.service';
import { DataLayerService } from '../services/data-layer.service';

const cartServiceFactory = (
  translationService: TranslationService,
  httpClient: HttpClient,
  genericPositionService: GenericPositionService,
  errorHandlingService: ErrorHandlingService,
  matSnackBar: MatSnackBar,
  dialogService: DialogService,
  confirmService: ConfirmService,
  punchOutCatalogService: PunchOutCatalogService,
  authenticationService: AuthenticationService,
  documentPositionSetConfigurationService: DocumentPositionSetConfigurationService,
  vatService: VatService,
  loadingService: LoadingService,
  dataLayerService: DataLayerService
): CartService | OfflineCartService => {
  if (punchOutCatalogService.isRequestingOCI || punchOutCatalogService.isRequestingCXML) {
    return new OfflineCartService(
      translationService,
      httpClient,
      genericPositionService,
      errorHandlingService,
      matSnackBar,
      dialogService,
      confirmService,
      punchOutCatalogService,
      documentPositionSetConfigurationService,
      vatService,
      loadingService,
      dataLayerService
    );
  } else {
    return new CartService(
      translationService,
      httpClient,
      genericPositionService,
      errorHandlingService,
      matSnackBar,
      dialogService,
      confirmService,
      authenticationService,
      documentPositionSetConfigurationService,
      vatService,
      loadingService,
      dataLayerService
    );
  }
};

export const CART_SERVICE_PROVIDER = {
  provide: BaseCartService,
  useFactory: cartServiceFactory,
  deps: [
    TranslationService,
    HttpClient,
    GenericPositionService,
    ErrorHandlingService,
    MatSnackBar,
    DialogService,
    ConfirmService,
    PunchOutCatalogService,
    AuthenticationService,
    DocumentPositionSetConfigurationService,
    VatService,
    LoadingService,
    DataLayerService,
  ],
};
