@if (elements.length > 0) {
  @for (element of elements; track $index) {
    <div class="skeleton-loading" [style.height.px]="elementHeight"></div>
    @if (showDivider) {
      <mat-divider></mat-divider>
    }
  }
} @else {
  <div class="skeleton-loading" [style.height.px]="elementHeight"></div>
}
