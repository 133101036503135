import { Platform } from '@angular/cdk/platform';
import { PlatformLocation } from '@angular/common';
import { ApplicationRef, Component, HostBinding, NgZone, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, merge } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

import { AppService } from './services/app.service';
import { AuthenticationService } from './services/authentication.service';
import { BannerService } from './services/banner.service';
import { BaseCartService } from './services/base-cart.service';
import { DropdownValuesService } from './services/dropdown-values.service';
import { TranslationService } from './services/translation.service';
import { PunchOutCatalogService } from './services/punch-out-catalog.service';
import { ENVIRONMENT } from '../environments/environment';

@Component({
  selector: 'portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('style.padding') public padding: string;
  @ViewChild('bannerViewRefAnchor', { read: ViewContainerRef, static: true }) private _bannerViewRefAnchor: ViewContainerRef;
  @ViewChild('matRipple') public matRipple: MatRipple;
  @HostBinding('style.top.px') public top: number;
  @HostBinding('style.height') public height: string;

  constructor(
    private _appRef: ApplicationRef,
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry,
    private _renderer: Renderer2,
    private _snackBar: MatSnackBar,
    public translationService: TranslationService,
    public platform: Platform,
    public appService: AppService,
    private _bannerService: BannerService,
    private _dropdownValuesService: DropdownValuesService,
    private _activatedRoute: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _ngZone: NgZone,
    private _platformLocation: PlatformLocation,
    private _punchOutCatalogService: PunchOutCatalogService,
    private _cartService: BaseCartService
  ) {}

  ngOnInit(): void {
    this._authenticationService.isAuthenticated$
      .pipe(
        tap((isAuthenticated) => (isAuthenticated ? this._dropdownValuesService.initializeDropdownValues() : null)),
        filter(() => !this._platformLocation.pathname.includes('/oci') && !this._platformLocation.pathname.includes('/cxml')),
        switchMap(() => {
          return this._cartService.getCartFromBackend();
        })
      )
      .subscribe();

    merge(this._punchOutCatalogService.ociModeActive$, this._punchOutCatalogService.cxmlModeActive$)
      .pipe(
        filter((active) => !!active),
        tap(() => {
          this.top = 0;
          this.height = '100%';
        })
      )
      .subscribe();

    window.portal = { navigate: (navigationPath: string): Promise<boolean> => this._ngZone.run(() => this._router.navigateByUrl(navigationPath)) };

    console.info('frontend revision: ' + ENVIRONMENT.git.revision);

    this._appRef.isStable
      .pipe(
        filter((isStable) => isStable === true),
        take(1),
        tap(() => this.appService.appIsStable$.next(true))
      )
      .subscribe();

    this._registerIcons();

    if (this.platform.IOS || this.platform.SAFARI) {
      this._renderer.addClass(document.body, 'ios');
    }

    this.appService.isMobile$.subscribe((isMobile: boolean) => {
      if (isMobile) {
        this._renderer.addClass(document.body, 'mobile');
      } else {
        this._renderer.removeClass(document.body, 'mobile');
      }
    });

    merge(fromEvent(window, 'dragover'), fromEvent(window, 'drop')).subscribe((event) => event.preventDefault());

    this.appService.isDesktop$.subscribe((isDesktop) => {
      this.padding = isDesktop ? '40px 60px 60px 60px' : undefined;
    });
  }

  private _registerIcons(): void {
    this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('./assets/icomoon-icons/icomoon.svg'));
    this._matIconRegistry.addSvgIcon('flag_de', this._domSanitizer.bypassSecurityTrustResourceUrl('./assets/flags/de.svg'));
    this._matIconRegistry.addSvgIcon('flag_gb', this._domSanitizer.bypassSecurityTrustResourceUrl('./assets/flags/gb.svg'));
  }
}
