import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { TranslationService } from '../services/translation.service';
import { MatIconModule } from '@angular/material/icon';
import { PhoneOrEmailModule } from '../phone-or-email/phone-or-email.module';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'portal-deactivated-page',
  standalone: true,
  templateUrl: './deactivated-page.component.html',
  styleUrls: ['./deactivated-page.component.scss'],
  imports: [MatIconModule, PhoneOrEmailModule, AsyncPipe],
})
export class DeactivatedPageComponent implements OnDestroy {
  constructor(
    public translationService: TranslationService,
    public authenticationService: AuthenticationService
  ) {}

  public ngOnDestroy(): void {
    this.authenticationService.accountManager$.next(null);
  }
}
