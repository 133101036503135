<div #dashboard (window:resize)="resize($event)" cdkScrollable class="dashboard relative h-full w-full overflow-y-auto overflow-x-hidden">
  @if ((ordersToApprove$ | async).length > 0) {
    <div class="cost-center-responsible-user-info">
      {{ translationService.translations.dashboard.CostCenterResponsibleUserInformation }}:&nbsp;
      @for (orderToApprove of ordersToApprove$ | async; track $index) {
        <portal-number-link
          [description]="orderToApprove.DocumentNo + (!$last ? ',' : '.')"
          [linkNumber]="orderToApprove.DocumentNo"
          [type]="'Order'"
          class="mat-title"
        ></portal-number-link>
      }
    </div>
  }

  <div class="first-row" [class.isMobile]="(appService.isMobile$ | async) === true">
    <mat-grid-list [cols]="3" [gutterSize]="'12px'" [rowHeight]="600" class="pb-0 pl-1.5 pr-3 pt-1.5">
      <mat-grid-tile [colspan]="gridListColumnCount >= 3 ? 1 : 3">
        <mat-card [class.isMobile]="(appService.isMobile$ | async) === true" class="open-invoices h-full w-full">
          <mat-card-header class="!inline h-20 justify-center">
            <mat-card-title class="!mx-2.5 !flex h-20 items-center justify-center text-2xl/[80px]">
              <mat-icon class="mr-1.5 !flex !min-h-[40px] !min-w-[40px] select-none items-center justify-center" svgIcon="enthus-invoices"></mat-icon>
              <p class="!m-[unset] truncate">{{ translationService.translations.dashboard.OpenInvoices }}</p>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content
            class="h-[calc(100%-theme('variables.matCardHeaderHeight'))] flex-auto overflow-hidden overflow-y-auto bg-whiteBackground dark:bg-blueBackground"
          >
            <portal-open-invoices></portal-open-invoices>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="gridListColumnCount >= 3 ? 1 : 3">
        <mat-card [class.isMobile]="(appService.isMobile$ | async) === true" class="open-positions h-full w-full">
          <mat-card-header class="!inline h-20 justify-center">
            <mat-card-title class="!mx-2.5 !flex h-20 items-center justify-center text-2xl/[80px]">
              <mat-icon class="mr-1.5 !flex !min-h-[40px] !min-w-[40px] select-none items-center justify-center" svgIcon="enthus-open_positions"></mat-icon>
              <p class="!m-[unset] truncate">{{ translationService.translations.dashboard.OpenPositions }}</p>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content
            class="h-[calc(100%-theme('variables.matCardHeaderHeight'))] flex-auto overflow-hidden overflow-y-auto bg-whiteBackground dark:bg-blueBackground"
          >
            <portal-open-positions></portal-open-positions>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="gridListColumnCount >= 3 ? 1 : 3">
        <mat-card [class.isMobile]="(appService.isMobile$ | async) === true" class="open-offers h-full w-full">
          <mat-card-header class="!inline h-20 justify-center">
            <mat-card-title class="!mx-2.5 !flex h-20 items-center justify-center text-2xl/[80px]">
              <mat-icon class="mr-1.5 !flex !min-h-[40px] !min-w-[40px] select-none items-center justify-center" svgIcon="enthus-offers"></mat-icon>
              <p class="!m-[unset] truncate">{{ translationService.translations.dashboard.OpenOffers }}</p>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content
            class="h-[calc(100%-theme('variables.matCardHeaderHeight'))] flex-auto overflow-y-auto overflow-x-hidden bg-whiteBackground dark:bg-blueBackground"
          >
            <portal-open-offers></portal-open-offers>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      @if (authenticationService.authenticationData?.ShowOnboardingTile) {
        <mat-grid-tile [colspan]="3">
          <mat-card class="onboarding h-full w-full">
            <mat-card-header class="!inline h-20 justify-center">
              <mat-card-title class="!mx-2.5 !flex h-20 items-center justify-center text-2xl/[80px]">
                <mat-icon class="mr-1.5 !flex !min-h-[40px] !min-w-[40px] select-none items-center justify-center" svgIcon="enthus-my_enthus"></mat-icon>
                <p class="!m-[unset] truncate">enthus connect</p>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content
              class="onboarding-container h-[calc(100%-theme('variables.matCardHeaderHeight'))] flex-auto overflow-hidden overflow-y-auto bg-whiteBackground dark:bg-blueBackground"
            >
              <portal-onboarding></portal-onboarding>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      }
    </mat-grid-list>
  </div>
  @if (dashboardOrders | async) {
    <portal-show-more
      [fetchItemsErrorMessage]="translationService.translations.error.FetchAssetsFailed.toString()"
      [trackByPropertyName]="'DocumentUUID'"
      [dataItems]="dashboardOrders | async"
      [position]="'relative'"
      [hideTabBar]="true"
      [showOnlyGalleryView]="true"
      [showContextualSearch]="false"
      class="w-full !bg-[unset]"
    >
      <ng-template let-element #galleryViewTemplate>
        <mat-card [class.isMobile]="(appService.isMobile$ | async) === true" class="order-status w-full">
          <mat-card-header class="!inline h-20 justify-center">
            <mat-card-title
              [matTooltip]="element.DocumentDescription || translationService.translations.dashboard.OrderStatus.toString()"
              class="!mx-2.5 !flex h-20 items-center justify-center text-2xl/[80px]"
            >
              <mat-icon class="mr-1.5 !flex !min-h-[40px] !min-w-[40px] select-none items-center justify-center" svgIcon="enthus-orders"></mat-icon>&nbsp;
              <portal-number-link [description]="element.DocumentNo" [linkNumber]="element.DocumentNo" [type]="'Order'" class="mat-title"></portal-number-link
              >&nbsp;
              <p class="!m-[unset] truncate" matLine>{{ element.DocumentDescription || translationService.translations.dashboard.OrderStatus }}</p>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content
            class="h-[calc(100%-theme('variables.matCardHeaderHeight'))] flex-auto overflow-hidden overflow-y-auto bg-whiteBackground dark:bg-blueBackground"
          >
            <portal-order-status [order]="element"></portal-order-status>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </portal-show-more>
  }
</div>
