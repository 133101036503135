<div
  class="tableContainer"
  [class.no-open-positions]="slicedOpenPositions?.length === 0"
  portalSkeleton
  [showSkeleton]="(showSkeleton$ | async) === true"
  [elementsCount]="8"
  [showDivider]="true"
  [elementHeight]="65"
>
  <table mat-table [dataSource]="slicedOpenPositions" [class.isMobile]="(appService.isMobile$ | async) === true">
    <ng-container matColumnDef="DocumentNo">
      <th mat-header-cell *matHeaderCellDef>{{ translationService.translations.openPositions.PositionOrderNo }}</th>
      <td mat-cell *matCellDef="let element">
        <portal-number-link [linkNumber]="element.DocumentNo" [type]="'Order'"></portal-number-link>
      </td>
    </ng-container>

    <ng-container matColumnDef="DocumentPositionStateDescription">
      <th mat-header-cell *matHeaderCellDef>
        {{ translationService.translations.openPositions.PositionState }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.DocumentPositionStateDescription[translationService.locale] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="DocumentPositionItemDescription">
      <th mat-header-cell *matHeaderCellDef>{{ translationService.translations.openPositions.PositionItemDescription }}</th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.DocumentPositionItemDescription">{{ element.DocumentPositionItemDescription }}</td>
    </ng-container>

    <ng-container matColumnDef="DocumentPositionExpectedDeliveryDate">
      <th mat-header-cell *matHeaderCellDef>{{ translationService.translations.openPositions.PositionExpectedDeliveryDate }}</th>
      <td mat-cell *matCellDef="let element">
        {{
          (element.DocumentPositionExpectedDeliveryDate | date: translationService.currentDateFormat) ||
            translationService.translations.information.NoExpectedDeliveryDate
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="DocumentPositionOpenQuantityTotal">
      <th mat-header-cell *matHeaderCellDef>{{ translationService.translations.openPositions.PositionQuantity }}</th>
      <td mat-cell *matCellDef="let element">
        <portal-quantity-key [quantity]="element.DocumentPositionOpenQuantityTotal" [symbol]="element.DocumentPositionQuantityKeySymbol"></portal-quantity-key>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="show-more-wrapper">
    @if (openPositions?.length > 6) {
      <a [routerLink]="'/openpositions'">
        {{ translationService.translations.information.ShowMore }}
      </a>
    }
  </div>
</div>
@if (slicedOpenPositions?.length === 0) {
  <div class="no-open-positions-placeholder-container">
    <div class="no-open-positions-placeholder">{{ translationService.translations.dashboard.NoOpenPositions }}</div>
  </div>
}
