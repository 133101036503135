import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceEmailProclamationModule } from '../invoice-email-proclamation/invoice-email-proclamation.module';
import { UserEditFormModule } from '../user-edit-form/user-edit-form.module';
import { GettingStartedComponent } from './getting-started.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ThemePreviewComponent } from './theme-preview/theme-preview.component';

@NgModule({
  declarations: [GettingStartedComponent, ThemePreviewComponent],
  imports: [CommonModule, UserEditFormModule, MatStepperModule, MatButtonToggleModule, MatIconModule, MatButtonModule, InvoiceEmailProclamationModule],
})
export class GettingStartedModule {}
