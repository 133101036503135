import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BaseDialogModule } from '../base-dialog/base-dialog.module';
import { MarkerSketchModule } from '../marker-sketch/marker-sketch.module';
import { ErrorDialogComponent } from './error-dialog.component';

@NgModule({
  declarations: [ErrorDialogComponent],
  exports: [ErrorDialogComponent],
  imports: [CommonModule, BaseDialogModule, MatButtonModule, MarkerSketchModule, MatIconModule],
})
export class ErrorDialogModule {}
