import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BaseDialogModule } from '../base-dialog/base-dialog.module';
import { MarkerSketchModule } from '../marker-sketch/marker-sketch.module';
import { PhoneOrEmailModule } from '../phone-or-email/phone-or-email.module';
import { FeedbackCategoryComponent } from './feedback-category/feedback-category.component';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { FeedbackPageComponent } from './feedback-page/feedback-page.component';
import { FeedbackTextboxComponent } from './feedback-textbox/feedback-textbox.component';
import { FeedbackComponent } from './feedback.component';
import { ThanksForFeedbackDialogComponent } from './thanks-for-feedback-dialog/thanks-for-feedback-dialog.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    FeedbackComponent,
    FeedbackPageComponent,
    FeedbackCategoryComponent,
    FeedbackTextboxComponent,
    ThanksForFeedbackDialogComponent,
    FeedbackDialogComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    BaseDialogModule,
    MarkerSketchModule,
    PhoneOrEmailModule,
    MatDividerModule,
  ],
  exports: [FeedbackPageComponent],
})
export class FeedbackModule {}
