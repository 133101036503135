import { Component, Input } from '@angular/core';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'portal-theme-preview',
  templateUrl: './theme-preview.component.html',
  styleUrls: ['./theme-preview.component.scss'],
})
export class ThemePreviewComponent {
  @Input() public theme: string;

  constructor(public appService: AppService) {}
}
