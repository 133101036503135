import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlingService } from '../services/error-handling.service';
import { AuthenticationService } from '../services/authentication.service';
import { TranslationService } from '../services/translation.service';
import { MarkerSketchModule } from '../marker-sketch/marker-sketch.module';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'portal-logout',
  standalone: true,
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  imports: [MarkerSketchModule],
})
export class LogoutComponent implements OnInit {
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    public translationService: TranslationService,
    private _errorHandlingService: ErrorHandlingService,
    public loadingService: LoadingService
  ) {}

  public ngOnInit(): void {
    this.loadingService.isLoading$.next(false);

    this._errorHandlingService
      .handleErrorWithBannerAndRetry<void>({
        endpoint: this._authenticationService.logout(),
        propertyModification: null,
        bannerTitle: this.translationService.translations.error.LogoutFailed.toString(),
        bannerSubtitle: '',
        sideSheetComponent: null,
        onDismiss: null,
        rethrowError: true,
      })
      .subscribe();
  }
}
