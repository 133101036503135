import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export function maintenanceGuard(): CanActivateFn {
  return (): boolean => {
    const router = inject(Router);
    const currentNavigation = router.getCurrentNavigation();

    if (currentNavigation?.extras?.state?.status === 503) {
      return true;
    } else {
      router.navigate(['/dashboard']);
      return false;
    }
  };
}
