import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { SkeletonDirective } from './skeleton.directive';
import { SkeletonComponent } from './skeleton.component';

@NgModule({
  declarations: [SkeletonComponent, SkeletonDirective],
  imports: [CommonModule, MatDividerModule],
  exports: [SkeletonDirective],
})
export class SkeletonModule {}
