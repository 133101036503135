<div [elementHeight]="65" [elementsCount]="8" [showDivider]="true" [showSkeleton]="(showSkeleton$ | async) === true" class="tableContainer" portalSkeleton>
  <table mat-table [dataSource]="openInvoices" [class.isMobile]="(appService.isMobile$ | async) === true">
    <ng-container matColumnDef="DocumentNo">
      <th mat-header-cell *matHeaderCellDef>{{ translationService.translations.openOffers.DocumentNo }}</th>
      <td mat-cell *matCellDef="let element">
        <portal-number-link [linkNumber]="element.DocumentNo" [type]="'Invoice'"></portal-number-link>
      </td>
    </ng-container>

    <ng-container matColumnDef="DocumentSalesPriceTotal">
      <th mat-header-cell *matHeaderCellDef class="table-cell-alignment-right">{{ translationService.translations.price.Total }}</th>
      <td mat-cell *matCellDef="let element" class="table-cell-alignment-right">
        <portal-gross-and-net-price
          [grossPrice]="element.DocumentSalesPriceTotalGross"
          [netPrice]="element.DocumentSalesPriceTotalNet"
          [currencyISOSymbol]="element.CurrencyISOSymbol"
          [alignment]="'right'"
        ></portal-gross-and-net-price>
      </td>
    </ng-container>

    <ng-container matColumnDef="DocumentPaymentRemainingAmount">
      <th mat-header-cell *matHeaderCellDef class="table-cell-alignment-right">
        {{ translationService.translations.openInvoices.DocumentPaymentRemainingAmount }}
      </th>
      <td mat-cell *matCellDef="let element" class="table-cell-alignment-right">
        {{ element.DocumentPaymentRemainingAmountGross | currency: element.CurrencyISOSymbol : 'symbol-narrow' : '.2-2' : translationService.locale }}
      </td>
    </ng-container>

    <ng-container matColumnDef="DocumentPaymentDate">
      <th mat-header-cell *matHeaderCellDef>{{ translationService.translations.openInvoices.DocumentPaymentDate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.DocumentPaymentDate | date: translationService.currentDateFormat }}</td>
    </ng-container>

    <ng-container matColumnDef="DocumentUUID">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <portal-pdf-download [isMenuIconButton]="true" [uuid]="element.DocumentUUID"></portal-pdf-download>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
@if (openInvoices?.length === 0) {
  <div class="no-open-invoices-placeholder-container">
    <div class="no-open-invoices-placeholder">{{ translationService.translations.dashboard.NoOpenInvoices }}</div>
  </div>
}
