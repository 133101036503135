import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ENVIRONMENT } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RevisionService {
  constructor(private _httpClient: HttpClient) {}

  public hasCorrectRevision(): Observable<boolean> {
    let occurredError;

    if (ENVIRONMENT.production) {
      return this._httpClient.post<void>('api/revision', { CommitHash: ENVIRONMENT.git.revision }).pipe(
        catchError((error) => {
          occurredError = error;
          return of(void 0);
        }),
        map(() => !occurredError)
      );
    } else {
      return of(true);
    }
  }
}
