import { APP_BASE_HREF, CurrencyPipe, DatePipe, DecimalPipe, PlatformLocation, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats, MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';

import * as sentry from '@sentry/angular';
import { EMPTY, fromEvent, Observable, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { ENVIRONMENT } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CartModule } from './cart/cart.module';
import { MasterDetailRouteReuseStrategy } from './master-detail-route-reuse-strategy';
import { CART_SERVICE_PROVIDER } from './providers/cart-service-provider';
import { RoutingModule } from './routing/routing.module';
import { AuthenticationService, IAccountManager } from './services/authentication.service';
import { UserService } from './services/user.service';
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './custom-overlay-container';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { GalleryViewModule } from './gallery-view/gallery-view.module';
import { SerialNumbersModule } from './serial-numbers/serial-numbers.module';
import { PhoneOrEmailModule } from './phone-or-email/phone-or-email.module';
import { MarkerSketchModule } from './marker-sketch/marker-sketch.module';
import { LoginDialogModule } from './login/login-dialog/login-dialog.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FeedbackModule } from './feedback/feedback.module';
import { CreditsModule } from './credits/credits.module';
import { ErrorDialogModule } from './error-dialog/error-dialog.module';
import { LoadingComponent } from './loading/loading.component';
import { BannerModule } from './banner/banner.module';
import { ForbiddenModule } from './forbidden/forbidden.module';
import { DashboardModule } from './dashboard/dashboard.module';

registerLocaleData(localeDe, 'de');

const PORTAL_MAT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  extractError(error): Error | string {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error?.ngOriginalError) {
      error = error.ngOriginalError;
    }
    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }
    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }
      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }
      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }
      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // ***** CUSTOM *****
    // The above code doesn't always work since 'instanceof' relies on the object being created with the 'new' keyword
    if (error.error?.message) {
      return error.error.message;
    }
    if (error.message) {
      return error.message;
    }
    // ***** END CUSTOM *****

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error): void {
    // Attempt to extract an Error object or a message
    const extractedError = this.extractError(error);

    // Send error to Sentry
    if (extractedError) {
      const chunkFailedMessage = /Loading chunk \d+ failed/;
      if (chunkFailedMessage.test(typeof extractedError === 'string' ? extractedError : error.message)) {
        window.location.reload();
      } else {
        sentry.withScope((scope) => {
          scope.setExtra('capturedError', JSON.stringify(extractedError));
        });
        sentry.captureException('Loading a chunk failed');
      }
    } else {
      // If we weren't able to extract data from the usual spots, attach the
      // originally-captured object as `extra` for debugging purposes
      sentry.withScope((scope) => {
        scope.setExtra('capturedError', JSON.stringify(error));
      });
      sentry.captureException('Handled unknown error');
    }

    // When in development mode, log the error to console for immediate feedback.
    if (!ENVIRONMENT.production) {
      console.error(extractedError || `Handled unknown error: ${JSON.stringify(error)}`);
    }
  }
}

@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  constructor(
    private _userService: UserService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _httpClient: HttpClient
  ) {}

  // eslint-disable-next-line max-lines-per-function
  public intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const context = sentry.getActiveSpan()?.spanContext();
    let reqClone: HttpRequest<T>;

    if (context) {
      reqClone = req.clone({
        headers: req.headers.append('sentry-trace', context.traceId + '-' + context.spanId),
      });
    }

    return next.handle(reqClone || req).pipe(
      filter((event) => event instanceof HttpResponse || Object.values(HttpEventType).includes(event.type)),
      tap((event: HttpResponse<T>) => {
        if (event instanceof HttpResponse && event.headers.get('x-sessionvaliduntil') && !event.url.includes('otp/validate')) {
          this._authenticationService.refreshExpirationTimer$.next(event.headers.get('x-sessionvaliduntil'));
        }
      }),
      catchError((error) => {
        if (
          error?.error &&
          typeof error.error === 'string' &&
          (error.error?.includes('ChunkLoadError') ||
            error.name?.includes('ChunkLoadError') ||
            error.toString().includes('ChunkLoadError') ||
            /Loading chunk \d+ failed/.test(error.error) ||
            /Loading chunk \d+ failed/.test(error.toString()))
        ) {
          sentry.captureException(new Error('Handeled a "failed to load chunks" error. Reloading page to fetch fresh chunks.'));

          location.reload();
        }

        if (error.status === 401 && !this._userService.currentUser$?.value && localStorage.getItem('session-expiration')) {
          if (req.url !== 'api/cart') {
            this._userService.currentUser$.next(null);
            this._router.navigate(['forbidden']);
          }
        }

        if (error.status === 403 && this._authenticationService?.authenticationData && !this._authenticationService?.authenticationData?.IsReadOnlySession) {
          if (typeof error.error === 'string' && (error.error?.includes('activation outstanding') || error.error?.includes('validation outstanding'))) {
            this._router.navigate(['/registration/verification']);
          } else if (!(typeof error.error === 'string' && error.error?.includes('OTP is not valid'))) {
            const accountManager: IAccountManager = JSON.parse(JSON.stringify(error.error));

            if (this._authenticationService.authenticationState$.value) {
              return this._httpClient.get('api/logout').pipe(
                switchMap(() => {
                  this._authenticationService.authenticationState$.next(false);
                  this._authenticationService.accountManager$.next(accountManager);
                  this._router.navigate(['deactivated']);

                  return throwError(() => error);
                })
              );
            } else {
              this._authenticationService.authenticationState$.next(false);
              this._authenticationService.accountManager$.next(accountManager);
              this._router.navigate(['deactivated']);
            }
          }
        }

        if (error.status === 503) {
          this._router.navigate(['maintenance'], { state: { status: 503 } });
        }

        // cancelled requests, adblocker might throw '0 Unknown Error'
        if (error.status === 0) {
          return EMPTY;
        }

        return throwError(() => error);
      })
    );
  }
}

const tooltipDefaultOptions: Partial<MatTooltipDefaultOptions> = {
  touchGestures: 'off',
};

const dialogDefaultOptions: Partial<MatDialogConfig> = {
  autoFocus: false,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    RoutingModule,
    DashboardModule,
    ForbiddenModule,
    BannerModule,
    CartModule,
    CreditsModule,

    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatBottomSheetModule,
    GalleryViewModule,
    SerialNumbersModule,
    PhoneOrEmailModule,
    MarkerSketchModule,
    MatTooltipModule,
    LoginDialogModule,
    FeedbackModule,
    ErrorDialogModule,
    LoadingComponent,
    OverlayModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation): string => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    { provide: RouteReuseStrategy, useClass: MasterDetailRouteReuseStrategy },
    {
      provide: ErrorHandler,
      useValue: sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => (): void => {},
      deps: [sentry.TraceService],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: PORTAL_MAT_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaultOptions },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: dialogDefaultOptions },
    DecimalPipe,
    DatePipe,
    CurrencyPipe,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', color: 'accent' } },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    CART_SERVICE_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    const sentryKeyCombination = 'ControlAltShiftSENTRY';
    let combinationBuffer: string[] = [];

    fromEvent<KeyboardEvent>(window, 'keydown')
      .pipe(
        map((x) => x.key),
        distinctUntilChanged(),
        tap((pressedKey: string) => {
          combinationBuffer.unshift(pressedKey);
          combinationBuffer = combinationBuffer.slice(0, 9);

          if ([...combinationBuffer].reverse().join('') === sentryKeyCombination) {
            console.info('Custom test error has been sent to Sentry');
            sentry.captureException(new Error('Sentry secret test key combination has been pressed!'));
          }
        })
      )
      .subscribe();
  }
}
