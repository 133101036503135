<portal-base-dialog
  [header]="translationService.translations.feedback.RequestAppointmentTitle.toString()"
  (primaryAction)="sendAppointmentRequest()"
  [confirmButtonDescription]="translationService.translations.action.Send.toString()"
  [dismissButtonDescription]="translationService.translations.action.Close.toString()"
  [isConfirmButtonDisabled]="onboardingForm.invalid"
  [icon]="null"
>
  <div class="base-dialog-content">
    <mat-form-field color="accent" [floatLabel]="'auto'">
      <mat-label>{{ translationService.translations.feedback.Date }}</mat-label>
      <input
        matInput
        [formControl]="$any(onboardingForm).controls['OnboardingDate']"
        [matDatepicker]="$any(datePicker)"
        [min]="minDate"
        (click)="datePicker.open()"
        readonly
        tabindex="-1"
      />
      <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker [touchUi]="(appService.isMobile$ | async) === true && !appService.desktopIsForced" [color]="'accent'" #datePicker></mat-datepicker>
    </mat-form-field>
    <span>{{ translationService.translations.feedback.OnboardingAppointmentInformation }}</span>
    <mat-form-field color="accent">
      <mat-label>{{ translationService.translations.feedback.OnboardingMessage }}</mat-label>
      <textarea matInput [rows]="10" [maxLength]="1000" [formControl]="$any(onboardingForm).controls['OnboardingMessage']"></textarea>
    </mat-form-field>
  </div>
</portal-base-dialog>
