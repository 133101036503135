import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from '../../../services/app.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { TranslationService } from '../../../services/translation.service';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';

import { DashboardService } from '../../../services/dashboard.service';
import { FeedbackService } from '../../../services/feedback.service';
import { PortalFormGroup } from '../../../danger-zone/form-group-override';
import { PortalFormControl } from '../../../danger-zone/form-control-override';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'portal-onboarding-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
})
export class OnboardingAppointmentComponent implements OnDestroy {
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public onboardingForm: PortalFormGroup;
  public minDate: Date = new Date();

  constructor(
    private _dialogRef: MatDialogRef<OnboardingAppointmentComponent>,
    public appService: AppService,
    public translationService: TranslationService,
    private _dashboardService: DashboardService,
    private _feedbackService: FeedbackService,
    private _matSnackBar: MatSnackBar,
    private _authenticationService: AuthenticationService,
    public loadingService: LoadingService
  ) {
    this.onboardingForm = new PortalFormGroup({
      OnboardingMessage: new PortalFormControl(this._destroyEmitter, ''),
      OnboardingDate: new PortalFormControl(this._destroyEmitter, '', Validators.required),
    });
  }

  public sendAppointmentRequest(): void {
    this.loadingService.isLoading$.next(true);
    this.onboardingForm.disable();
    let errorOccurred;

    this._feedbackService
      .requestOnboarding(this.onboardingForm.value)
      .pipe(
        finalize(() => {
          this.loadingService.isLoading$.next(false);
          this.onboardingForm.enable();
          this.onboardingForm.markAsPristine();

          const message: string = errorOccurred
            ? this.translationService.translations.feedback.OnboardingFeedbackFailed.toString()
            : this.translationService.translations.feedback.OnboardingAppointmentSuccess.toString();
          this._matSnackBar.open(message, null, { duration: 5000 });

          this._dialogRef.close();
        })
      )
      .subscribe({
        next: () => (this._authenticationService.authenticationData.ShowOnboardingTile = false),
        error: () => (errorOccurred = true),
      });
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
