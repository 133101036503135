import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { DropdownValuesService } from '../../services/dropdown-values.service';
import { FeedbackService } from '../../services/feedback.service';
import { TranslationService } from '../../services/translation.service';
import { ISendFeedbackOptions } from '../../interfaces/feedback.interface';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'portal-feedback-page',
  templateUrl: './feedback-page.component.html',
  styleUrls: ['./feedback-page.component.scss'],
})
export class FeedbackPageComponent implements OnInit, OnDestroy {
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() feedbackTitle: string = this.translationService.translations.information.SendFeedback.toString();
  @Input() icon = 'enthus-feedback';
  @Input() infoText: string = this.translationService.translations.feedback.HowDoYouFind.toString();
  @Input() infoSubtext: string = this.translationService.translations.feedback.Important.toString();
  @Input() showAlternativeContactOptions = false;
  @Input() action: () => void;
  @Input() showBackdrop = false;

  constructor(
    public translationService: TranslationService,
    public feedbackService: FeedbackService,
    private _dropdownValuesService: DropdownValuesService
  ) {
    this.feedbackService.resetFeedback(this._destroyEmitter);

    if (!this._dropdownValuesService.dropdownValues$.value) {
      this._dropdownValuesService.initializeDropdownValues();
    }
  }

  ngOnInit(): void {
    this.feedbackService.loadingService.isLoading$.next(false);
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  sendFeedback(config: ISendFeedbackOptions): void {
    let occurredError;

    this.feedbackService
      .sendFeedback(config)
      .pipe(
        takeUntil(this._destroyEmitter),
        catchError((error) => {
          occurredError = error;

          return EMPTY;
        }),
        finalize(() => {
          if (!occurredError) {
            FeedbackDialogComponent.closeDialog();
          }
        })
      )
      .subscribe();
  }
}
