import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService, ILatestTos } from '../services/authentication.service';
import { map } from 'rxjs/operators';

export function latestTosGuard(): CanActivateFn {
  return (): Observable<boolean> => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);

    return authenticationService.checkLatestTos().pipe(
      map((latestTos: ILatestTos) => {
        if (latestTos.AcceptedLatestToS) {
          return true;
        } else {
          router.navigate(['/termsofservice']);
          return false;
        }
      })
    );
  };
}
