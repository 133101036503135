import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { RoutingComponent } from './routing.component';
import { GettingStartedModule } from '../getting-started/getting-started.module';
import { DevNavigationComponent } from '../dev-navigation/dev-navigation.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [RoutingComponent],
  imports: [CommonModule, BreadcrumbsModule, GettingStartedModule, DevNavigationComponent, MatSidenavModule, RouterOutlet, MatDialogModule],
})
export class RoutingModule {}
