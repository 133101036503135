import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICartGeneric } from '../interfaces/cart.interface';
import { BaseCartService } from '../services/base-cart.service';
import { IUser, UserService } from '../services/user.service';

export function checkoutGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot): Observable<boolean> => {
    const router = inject(Router);
    const userService = inject(UserService);
    const cartService = inject(BaseCartService);

    return combineLatest([userService.currentUser$.asObservable(), cartService.getCartFromBackend()]).pipe(
      map(([user, cart]: [IUser, ICartGeneric]) => {
        if (
          (!!user && user.UserPermissionNames.includes('AllowCheckout') && route.queryParams.document === 'order') ||
          route.queryParams.document === 'offer'
        ) {
          if (cart.CartPositions.length > 0) {
            return true;
          } else {
            router.navigate(['cart']);
            return false;
          }
        } else {
          router.navigate(['']);
          return false;
        }
      })
    );
  };
}
