import { Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { SkeletonComponent } from './skeleton.component';

@Directive({
  selector: '[portalSkeleton]',
})
export class SkeletonDirective implements OnChanges {
  @Input() public showSkeleton = false;
  @Input() public elementsCount = 0;
  @Input() public elementHeight = 0;
  @Input() public showDivider = false;

  constructor(private _viewContainerRef: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showSkeleton) {
      this._viewContainerRef.clear();

      if (changes.showSkeleton.currentValue) {
        const componentRef = this._viewContainerRef.createComponent(SkeletonComponent);
        componentRef.instance.elementsCount = this.elementsCount;
        componentRef.instance.showDivider = this.showDivider;
        componentRef.instance.elementHeight = this.elementHeight;
      }
    }
  }
}
