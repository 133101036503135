import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseDialogModule } from '../base-dialog/base-dialog.module';
import { AddSerialNumbersDialogComponent } from './add-serial-numbers-dialog/add-serial-numbers-dialog.component';
import { SerialNumbersInfoDialogComponent } from './serial-numbers-info-dialog/serial-numbers-info-dialog.component';
import { SerialNumbersDialogComponent } from './serial-numbers-dialog/serial-numbers-dialog.component';

@NgModule({
  declarations: [SerialNumbersDialogComponent, SerialNumbersInfoDialogComponent, AddSerialNumbersDialogComponent],
  imports: [
    CommonModule,
    BaseDialogModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatListModule,
    MatAutocompleteModule,
  ],
})
export class SerialNumbersModule {}
