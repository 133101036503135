<h2>{{ translationService.translations.feedback.YourPersonalOnboarding }}</h2>
<h4>{{ translationService.translations.feedback.OnboardingInformation }}</h4>

<div class="image-wrapper">
  <mat-icon [svgIcon]="'enthus-onboarding'"></mat-icon>
</div>

<div class="actions">
  <button mat-raised-button color="accent" (click)="setNoInterest()">
    <span>{{ translationService.translations.feedback.NotInterested }}</span>
  </button>
  <button mat-raised-button color="primary" (click)="openAppointmentDialog()">
    <span>{{ translationService.translations.feedback.RequestAppointment }}</span>
  </button>
</div>
