import { Component, Input } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'portal-gross-and-net-price',
  templateUrl: './gross-and-net-price.component.html',
  styleUrls: ['./gross-and-net-price.component.scss'],
})
export class GrossAndNetPriceComponent {
  @Input() public netPrice: number;
  @Input() public grossPrice: number;
  @Input() public currencyISOSymbol: string;
  @Input() public alignment: 'left' | 'right' | 'center' = 'left';

  constructor(public translationService: TranslationService) {}
}
