import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { catchError, concatMap, filter, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';

import { DialogService } from '../../services/dialog.service';
import { TranslationService } from '../../services/translation.service';
import { ConfirmService } from '../../confirm/confirm.service';
import { FeedbackService } from '../../services/feedback.service';
import { OnboardingAppointmentComponent } from './appointment/appointment.component';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'portal-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent {
  constructor(
    public translationService: TranslationService,
    private _dialogService: DialogService,
    private _confirmService: ConfirmService,
    private _feedbackService: FeedbackService,
    private _matSnackBar: MatSnackBar,
    private _authenticationService: AuthenticationService,
    public loadingService: LoadingService
  ) {}

  public openAppointmentDialog(): void {
    this._dialogService.openDialog(OnboardingAppointmentComponent, { hasBackdrop: true });
  }

  public setNoInterest(): void {
    this._confirmService
      .getConfirmation(
        this.translationService.translations.feedback.NotInterested.toString(),
        this.translationService.translations.feedback.OnboardingNotInterestedConfirmation.toString()
      )
      .pipe(
        filter((confirmed) => confirmed),
        catchError((getConfirmationError) => {
          return throwError(() => getConfirmationError);
        }),
        tap(() => {
          this.loadingService.isLoading$.next(true);
        }),
        concatMap(() =>
          this._feedbackService.declineOnboarding().pipe(
            tap(() => {
              this.loadingService.isLoading$.next(false);
            }),
            catchError((error) => {
              return throwError(() => error);
            })
          )
        )
      )
      .subscribe({
        next: () => {
          this._matSnackBar.open(this.translationService.translations.feedback.OnboardingNotInterestedSuccess.toString(), 'Ok', { duration: 5000 });
          this._authenticationService.authenticationData.ShowOnboardingTile = false;
        },
        error: () => {
          this._matSnackBar.open(this.translationService.translations.feedback.OnboardingFeedbackFailed.toString(), 'Ok', { duration: 10000 });
        },
      });
  }
}
