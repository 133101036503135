import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';
import { CanActivateFn } from '@angular/router';

export function getUserBeforeNavigationGuard(): CanActivateFn {
  return (): Observable<true> => {
    return inject(AuthenticationService)
      .isUserAuthenticated()
      .pipe(map(() => true));
  };
}
