import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';

export function forbiddenGuard(): CanActivateFn {
  return (): Observable<boolean> => {
    const router = inject(Router);
    const userService = inject(UserService);

    return userService.getCurrentUser().pipe(
      catchError(() => of(undefined)),
      map((user) => {
        if (user) {
          router.navigate(['']);
        }

        return !user;
      })
    );
  };
}
