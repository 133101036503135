import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AppService } from '../../services/app.service';

import { TranslationService } from '../../services/translation.service';
import { DashboardService, IDashboardOpenInvoice } from '../../services/dashboard.service';

@Component({
  selector: 'portal-open-invoices',
  templateUrl: './open-invoices.component.html',
  styleUrls: ['./open-invoices.component.scss'],
})
export class OpenInvoicesComponent implements OnDestroy {
  public displayedColumns: string[] = ['DocumentNo', 'DocumentPaymentDate', 'DocumentSalesPriceTotal', 'DocumentPaymentRemainingAmount', 'DocumentUUID'];
  public openInvoices: IDashboardOpenInvoice[];
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public showSkeleton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    public translationService: TranslationService,
    private _dashboardService: DashboardService,
    public appService: AppService
  ) {
    this._dashboardService
      .getDashboardOpenInvoices()
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((openInvoices: IDashboardOpenInvoice[]) => {
          this.openInvoices = openInvoices;
          this.showSkeleton$.next(false);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
