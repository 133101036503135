import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../services/translation.service';
import { FeedbackModule } from '../feedback/feedback.module';

@Component({
  selector: 'portal-error-page',
  templateUrl: './error-page.component.html',
  standalone: true,
  styleUrls: ['./error-page.component.scss'],
  imports: [FeedbackModule],
})
export class ErrorPageComponent {
  constructor(
    public translationService: TranslationService,
    private _router: Router
  ) {}

  public authenticate(): void {
    this._router.navigate(['/errorRedirect']);
  }

  public navigateToStartRoute(): void {
    this._router.navigate(['/']);
  }
}
