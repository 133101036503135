export class Logger {
  private readonly _className: string;
  private _verboseLogging = localStorage.getItem('verboseLogging') === 'true';
  private readonly _formattedClassName;

  constructor(className: string) {
    this._className = className;
    this._formattedClassName = `%c${this._className}:`;
  }

  public logVerbose(...data: unknown[]): void {
    if (this._verboseLogging) console.log(this._formattedClassName, 'color: rgb(145, 145, 145)', ...data);
  }

  public infoVerbose(...data: unknown[]): void {
    if (this._verboseLogging) console.info(this._formattedClassName, 'color: rgb(42, 83, 205)', ...data);
  }
}
