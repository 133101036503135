import { Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { GettingStartedComponent } from '../getting-started/getting-started.component';
import { Overlay } from '@angular/cdk/overlay';
import { DeviceType, AppService, Orientation } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class GettingStartedService {
  constructor(
    private _overlay: Overlay,
    private _appService: AppService
  ) {}

  public showGettingStartedOverlay(): void {
    const overlayRef = this._overlay.create({
      hasBackdrop: true,
      positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
    });

    this._appService.typeAndOrientationChange$.subscribe(({ type, orientation }) => {
      if (type === DeviceType.HANDSET || (type === DeviceType.TABLET && orientation === Orientation.PORTRAIT)) {
        overlayRef.updateSize({ height: '100%' });
      } else {
        overlayRef.updateSize({ height: '' });
      }
    });

    const gettingStartedPortal = new ComponentPortal(GettingStartedComponent);
    const gettingStartedComponentRef = overlayRef.attach(gettingStartedPortal);
    gettingStartedComponentRef.instance.overlayRef = overlayRef;
  }
}
