<mat-horizontal-stepper #stepper>
  <mat-step [label]="translationService.translations.gettingStarted.Welcome.toString()" state="welcome">
    <div class="stepper-content-outer-container">
      <div class="stepper-content welcome-container" [class.mobile]="(appService.isDesktop$ | async) === false">
        <div class="stepper-content-header">{{ translationService.translations.gettingStarted.WelcomeTitle }}</div>
        <div class="stepper-content-inner-container">
          <div class="logo-container">
            <img [class.mobile]="(appService.isMobile$ | async) === true" src="assets/images/enthus_connect_app.png" />
          </div>
          <div class="welcome-text-container">{{ translationService.translations.gettingStarted.WelcomeText }}</div>
        </div>
      </div>
      <div class="stepper-actions" [class.mobile]="(appService.isDesktop$ | async) === false">
        <button mat-button class="skip-button sub-text-color" (click)="close()">
          {{ translationService.translations.gettingStarted.Skip }}
        </button>
        <div class="stepper-actions-container">
          <button mat-raised-button color="primary" matStepperNext>
            {{ translationService.translations.gettingStarted.Next }}
          </button>
        </div>
      </div>
    </div>
  </mat-step>
  <mat-step [label]="translationService.translations.gettingStarted.Design.toString()" state="design">
    <div class="stepper-content-outer-container">
      <div
        class="stepper-content theme-container"
        [class.mobile]="(appService.isDesktop$ | async) === false && ((appService.isTablet$ | async) && (appService.isLandscape$ | async)) === false"
      >
        <div class="stepper-content-header">{{ translationService.translations.gettingStarted.Theme }}</div>
        <div
          class="stepper-content-inner-container"
          [style.flex-direction]="(appService.isLandscape$ | async) || (appService.isDesktop$ | async) ? 'row' : 'column'"
        >
          <mat-button-toggle
            [value]="'light-theme'"
            [disableRipple]="true"
            (change)="saveTheme($event.value)"
            [checked]="!(settingsService.settingsChange$ | async)?.DarkThemeEnabled"
            [class.theme-checked]="settingsService.themeClass === 'light-theme'"
          >
            <div class="theme-name bold">{{ translationService.translations.gettingStarted.LightTheme }}</div>
            <portal-theme-preview [theme]="'light-theme'"></portal-theme-preview>
          </mat-button-toggle>
          <mat-button-toggle
            [value]="'dark-theme'"
            [disableRipple]="true"
            (change)="saveTheme($event.value)"
            [checked]="(settingsService.settingsChange$ | async)?.DarkThemeEnabled"
            [class.theme-checked]="settingsService.themeClass === 'dark-theme'"
          >
            <div class="theme-name bold">{{ translationService.translations.gettingStarted.DarkTheme }}</div>
            <portal-theme-preview [theme]="'dark-theme'"></portal-theme-preview>
          </mat-button-toggle>
        </div>
      </div>
      <div class="stepper-actions" [class.mobile]="(appService.isDesktop$ | async) === false">
        <button mat-button class="skip-button sub-text-color" (click)="close()">
          {{ translationService.translations.gettingStarted.Skip }}
        </button>
        <div class="stepper-actions-container">
          <button mat-raised-button color="accent" matStepperPrevious>{{ translationService.translations.gettingStarted.Back }}</button>
          @if (stepper._steps?.length !== 2) {
            <button mat-raised-button color="primary" matStepperNext>
              {{ translationService.translations.gettingStarted.Next }}
            </button>
          } @else {
            <button mat-raised-button color="primary" (click)="saveUserChange()">
              {{ translationService.translations.gettingStarted.Done }}
            </button>
          }
        </div>
      </div>
    </div>
  </mat-step>
  @if ((permissionToManageInformation$ | async) === true) {
    <mat-step [label]="translationService.translations.titles.Account.toString()" state="account">
      <div class="stepper-content-outer-container">
        <div class="stepper-content" [class.mobile]="(appService.isDesktop$ | async) === false">
          <div class="stepper-content-header">{{ translationService.translations.gettingStarted.MyData }}</div>
          <div class="stepper-content-inner-container grey-form-field-background">
            <portal-user-edit-form #userEditFormComponent></portal-user-edit-form>
          </div>
        </div>
        <div class="stepper-actions" [class.mobile]="(appService.isDesktop$ | async) === false">
          <button mat-button class="skip-button sub-text-color" (click)="close()">
            {{ translationService.translations.gettingStarted.Skip }}
          </button>
          <div class="stepper-actions-container">
            <button mat-raised-button color="accent" matStepperPrevious>{{ translationService.translations.gettingStarted.Back }}</button>
            @if (stepper._steps?.length !== 3) {
              <button mat-raised-button matStepperNext color="primary" [disabled]="userEditFormComponent.editUserForm.invalid">
                {{ translationService.translations.gettingStarted.Next }}
              </button>
            } @else {
              <button mat-raised-button color="primary" (click)="saveUserChange()">
                {{ translationService.translations.gettingStarted.Done }}
              </button>
            }
          </div>
        </div>
      </div>
    </mat-step>
  }
  @if ((permissionToManageCustomerInformation$ | async) === true && !hasCustomerInvoiceEMailAddresses) {
    <mat-step [label]="translationService.translations.common.InvoiceMailAddress.toString()" state="invoiceEmailAddresses">
      <div class="stepper-content-outer-container">
        <div class="stepper-content" [class.mobile]="(appService.isDesktop$ | async) === false">
          <div class="stepper-content-header">{{ translationService.translations.common.InvoiceMailAddressNotSet }}</div>
          <div class="stepper-content-inner-container grey-form-field-background">
            <portal-invoice-email-edit-form #invoiceEMailEditFormComponent></portal-invoice-email-edit-form>
          </div>
        </div>
        <div class="stepper-actions" [class.mobile]="(appService.isDesktop$ | async) === false">
          <button mat-button class="skip-button sub-text-color" (click)="close()">
            {{ translationService.translations.gettingStarted.Skip }}
          </button>
          <div class="stepper-actions-container">
            <button mat-raised-button color="accent" matStepperPrevious>
              {{ translationService.translations.gettingStarted.Back }}
            </button>
            <button mat-raised-button color="primary" (click)="saveUserChange()">
              {{ translationService.translations.gettingStarted.Done }}
            </button>
          </div>
        </div>
      </div>
    </mat-step>
  }

  <ng-template matStepperIcon="welcome">
    <mat-icon svgIcon="enthus-welcome"></mat-icon>
  </ng-template>
  <ng-template matStepperIcon="design">
    <mat-icon svgIcon="enthus-design"></mat-icon>
  </ng-template>
  <ng-template matStepperIcon="account">
    <mat-icon svgIcon="enthus-my_data"></mat-icon>
  </ng-template>
  <ng-template matStepperIcon="invoiceEmailAddresses">
    <mat-icon [svgIcon]="'enthus-alternate_email'"></mat-icon>
  </ng-template>
</mat-horizontal-stepper>
